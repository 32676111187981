import { Nullable } from "src/types/common";

export enum CountryFilterType {
  EXCLUDE = "exclude",
  INCLUDE = "include",
}

export enum DateFilterType {
  BEFORE = "before",
  AFTER = "after",
}

export type EmptyArgsFn<T> = () => T;
export type DataOrFunctionThatReturnsData<T> = T | EmptyArgsFn<T>;
export type DateCompareFunction = (
  date: Date | number,
  dateToCompare: Date | number
) => boolean;

export type IsEnabledSoc = DataOrFunctionThatReturnsData<boolean>;
export type CountriesFilterSoc =
  DataOrFunctionThatReturnsData<CountryFilterType>;
export type RegisterDateFilterSoc =
  DataOrFunctionThatReturnsData<DateFilterType>;
export type DateSoc = DataOrFunctionThatReturnsData<Nullable<Date>>;
export type CountriesSoc = DataOrFunctionThatReturnsData<string[]>;
export type IdsListSoc = DataOrFunctionThatReturnsData<string[]>;

export type FeatureControlProperties = {
  enabled: IsEnabledSoc;
  guest: IsEnabledSoc;
  country: CountriesSoc;
  countryFilter: CountriesFilterSoc;
  registerDateEnabled: IsEnabledSoc;
  registerDateFilter: RegisterDateFilterSoc;
  registerDate: DateSoc;
};
