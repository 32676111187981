import { datadogLogs } from "@datadog/browser-logs";
import filterNetworkErrors from "@analytics/setupDatadog/filterNetworkErrors";
import {
  getDatadogApiKey,
  getDatadogEnabled,
  getDatadogEnv,
  getDatadogSite,
} from "environment";

export default () => {
  const clientToken = getDatadogApiKey();
  const site = getDatadogSite();
  if (!getDatadogEnabled() || !clientToken || !site) return;

  datadogLogs.init({
    clientToken,
    site,
    forwardErrorsToLogs: true,
    service: "web-client",
    sessionSampleRate: 100,
    env: getDatadogEnv(),
    version: twcVersion,
    beforeSend: filterNetworkErrors,
  });
};
