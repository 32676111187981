import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getIsOneClickGiftingEnabledByDefault } from "state/abTests";
import { loadSettings } from "state/flows/settings";

export const useLoadSettings = () => {
  const dispatch = useDispatch();
  const isOneClickGiftingEnabledByDefault = useSelector(
    getIsOneClickGiftingEnabledByDefault
  );

  useEffect(() => {
    dispatch(loadSettings(isOneClickGiftingEnabledByDefault));
  }, [dispatch, isOneClickGiftingEnabledByDefault]);
};
