import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Gift } from "src/types/gift";
import { RootState } from "state/delegate";
import { loadSingleGift } from "state/flows/refreshGiftsDrawer";
import { giftsCacheSelectors } from "state/selectors";
import { StoredMessage } from "state/tree/chat";
import { useGiftMessage } from "ui/scenes/chat/currentConversation/useGiftMessage";

export const useGiftFromMessage = (message: StoredMessage) => {
  const dispatch = useDispatch();

  const { giftId } = useGiftMessage(message);

  const gift: Gift | undefined = useSelector(
    useCallback(
      (state: RootState) => giftsCacheSelectors.getGiftById(state, giftId),
      [giftId]
    )
  );

  useEffect(() => {
    if (!gift) {
      dispatch(loadSingleGift(giftId));
    }
  }, [dispatch, gift, giftId]);

  return gift;
};
