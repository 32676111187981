import { TCNNAction } from "src/enums";
import { VoidCallback } from "src/types/common";

export enum TransportType {
  LOCAL = 99,
  PUSH = 1,
}

export const BottomSheetNotificationChannel = "sendBottomSheet";

export type TCNN = {
  action: TCNNAction;
  action_cta_name: string;
  actionType?: string;
  bottom_sheet?: boolean | string;
  bottom_sheet_title?: string;
  category: string;
  deeplinkQuery?: {
    tab_id: string;
    target: string;
  };
  displayAreas?: string;
  force: string;
  giftId?: string;
  icon: string;
  imageSource?: string;
  link?: string;
  localized: {
    buttonKey: string;
    localizeTextKey: string;
    titleLocalizeKey: string;
  };
  message: string;
  message_id: string;
  message_uuid: string;
  notification_channel?: typeof BottomSheetNotificationChannel;
  onDismiss?: VoidCallback;
  priceInCredit?: string;
  receiverId?: string;
  rule_id?: string;
  serverOnly: string;
  session_id?: string;
  soundFile: string;
  subHeader?: string;
  supported: boolean;
  title: string;
  tracking_id: string;
  transport_type: TransportType;
  trigger_id: string;
  type: number;
  useAvatarProfileId?: string;
};
