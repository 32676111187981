import { AlbumPost } from "generated/proto/AlbumPost";
import { PicturePost } from "generated/proto/PicturePost";
import { Story } from "generated/proto/StoryPost";
import { TextPost } from "generated/proto/TextPost";
import { VideoPost } from "generated/proto/VideoPost";
import { WebLinkPost } from "generated/proto/WebLinkPost";
import {
  convertPostTypeCodeToPostType,
  transformNewPostContent,
} from "src/api/utils/postContentParsers";
import { Feed, FeedResponseData, Post, PostTypeCode } from "src/types/feed";
import {
  Content,
  MomentsResponse,
  MomentsResponseData,
} from "src/types/happyMoments";
import { parseMessageFromBase64 } from "src/utils/protobufUtil";

export const happyMomentsResponseAdapter = (
  responseData: MomentsResponseData
) => {
  const data = {
    stories: [],
    details: [],
    nextCursor: responseData.nextCursor,
    previousCursor: responseData.previousCursor,
    posts: responseData.posts,
  } as MomentsResponse;

  responseData.posts.forEach((post) => {
    const content = parseMessageFromBase64(post.content, Story) as Content;

    data.stories.push(content.storyId);
    data.details.push({
      giftId: content.giftId,
      streamerId: post.owner.accountId,
      gifterId: content.gifterId,
      videoLink: content.videoLink,
      streamerFirstName: post.owner.firstName,
      streamerProfileThumbnailUrl: post.owner.thumbnailUrl,
      storyId: content.storyId,
      likesCount: post.likes.totalCount,
      likedByMe: post.likes.likedByMe,
      watched: true,
      giftTimestamp: content.giftTimestamp,
      thumbnailLink: content.thumbnailLink,
      created: +post.createdTimestamp,
      showGifter: content.shownGifter,
      streamerVipConfigId: post.subscriptionDetails.streamer.level,
    });
  });

  return data;
};

export const postsResponseAdapter = (responseData: FeedResponseData): Feed => {
  const postMessageType = {
    [PostTypeCode.ALBUM]: AlbumPost,
    [PostTypeCode.PHOTO]: PicturePost,
    [PostTypeCode.TEXT]: TextPost,
    [PostTypeCode.VIDEO]: VideoPost,
    [PostTypeCode.WEBLINK]: WebLinkPost,
    [PostTypeCode.HAPPY_MOMENT]: Story,
  };

  return {
    errorCode: 0,
    errorMessage: "",
    nextCursor: responseData.nextCursor,
    previousCursor: responseData.previousCursor,
    posts: responseData.posts.map((postResponse): Post => {
      const type = convertPostTypeCodeToPostType(+postResponse.type);
      const likesCount = parseInt(postResponse.likes.totalCount, 10);
      const giftedPointsCount = parseInt(postResponse.gifts.pointsCount, 10);
      const timeCreated = parseInt(postResponse.createdTimestamp, 10);

      const content = parseMessageFromBase64(
        postResponse.content,
        postMessageType[postResponse.type as PostTypeCode]
      ) as Content;

      return {
        authorId: postResponse.owner.accountId,
        content: transformNewPostContent(+postResponse.type, content),
        giftedPointsCount,
        isLikedByMe: postResponse.likes.likedByMe,
        likesCount,
        postId: postResponse.postId,
        subscriptionDetails: {
          streamer: {
            level: postResponse.subscriptionDetails.streamer.level,
            maxAvailableUnlockExclusivePostCount: 0,
            streamerProfile: {
              accountId: postResponse.owner.accountId,
              firstName: postResponse.owner.firstName,
              lastName: null,
              pictureUrl: null,
              thumbnailUrl: postResponse.owner.thumbnailUrl,
            },
          },
        },
        timeCreated,
        type,
        unlocked: postResponse.unlocked,
      };
    }),
  };
};
