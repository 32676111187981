import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { ONE_CLICK_GIFTING } from "src/constants";
import LanguagePicker from "../languagePicker/LanguagePicker";
import Setting from "ui/scenes/settings/contents/app/Settings";
import {
  isSettingOptionAvailable,
  NSFW_KEY,
  PREMIUM_KEY,
  makeEnabledSettingSelector,
} from "ui/scenes/settings/contents/app/SettingsEnum";

const Options = forwardRef(({ options }, ref) => {
  const isNsfwDisabled = !useSelector(makeEnabledSettingSelector(NSFW_KEY));

  return (
    <>
      <div ref={ref} />
      <LanguagePicker />
      {isSettingOptionAvailable(NSFW_KEY) && (
        <>
          <Setting
            optionKey={NSFW_KEY}
            resetKeysOnDisable={options}
            setKeysOnEnable={options}
            checkKeysOnEnabled
            description={
              <FormattedMessage
                id="settings.mobile.nsfw.disclaimer"
                defaultMessage={
                  "By turning NSFW content you are enabling potentially sensitive text, images and videos to be surfaced." +
                  " This setting can not be activated for anonymous accounts. This setting only affects the current logged in account."
                }
              />
            }
          />
        </>
      )}
      {options.map(
        (x) =>
          isSettingOptionAvailable(x) && (
            <Setting
              key={x}
              optionKey={x}
              disabled={x === PREMIUM_KEY && isNsfwDisabled}
            />
          )
      )}
      {isSettingOptionAvailable(ONE_CLICK_GIFTING) && (
        <Setting optionKey={ONE_CLICK_GIFTING} />
      )}
    </>
  );
});

Options.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
};

Options.displayName = "Options";
export default Options;
