import React, { memo } from "react";
import classnames from "classnames";
import emptyFunction from "fbjs/lib/emptyFunction";
import { Breakpoints } from "src/enums";
import { ToastRegularProps } from "src/types/toast";
import Button, { ButtonSize, ButtonVariant } from "ui/common/button/Button";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import { useBreakpoint } from "ui/hooks/useBreakpoint";
import useIsOnStream from "ui/navigation/useIsOnStream";
import ToastWrapper from "ui/toast/ToastWrapper/ToastWrapper";
import { renderTextOrFormattedMessage } from "utils/renderTextOrFormattedMessage";
import styles from "./Toast.scss";

const ToastRegular: React.FC<ToastRegularProps> = ({
  IconComponent,
  handleButtonClick = emptyFunction,
  buttonText,
  title,
  id,
}) => {
  const breakpoint = useBreakpoint();
  const isOnStream = useIsOnStream();
  const isMobile = breakpoint !== Breakpoints.DESKTOP;
  const typographyType = isMobile
    ? TYPOGRAPHY_TYPE.PARAGRAPH2
    : TYPOGRAPHY_TYPE.PARAGRAPH1;
  const buttonSize = isMobile ? ButtonSize.MEDIUM_32 : ButtonSize.BIG_48;
  const formattedTitle = renderTextOrFormattedMessage(title);
  const formattedButtonText = renderTextOrFormattedMessage(buttonText);

  return (
    <ToastWrapper id={id}>
      <div className={classnames(styles.root, styles[breakpoint])}>
        <div className={styles.titleContainer}>
          {IconComponent && <IconComponent className={styles.toastIcon} />}
          <Typography type={typographyType} className={styles.title}>
            {formattedTitle}
          </Typography>
        </div>
        {buttonText && (
          <Button
            className={classnames(styles.button, {
              [styles.shader]: isOnStream,
            })}
            onClick={handleButtonClick}
            size={buttonSize}
            variant={ButtonVariant.TERTIARY}
          >
            {formattedButtonText}
          </Button>
        )}
      </div>
    </ToastWrapper>
  );
};

export default memo(ToastRegular);
