import { AutologinResult } from "src/enums";
import { createReducer } from "@reduxjs/toolkit";
import * as actionCreators from "../actionCreators/autoLogin";

const initialState = {
  inProgress: false,
  result: AutologinResult.AUTO_LOGIN_RESULT_UNKNOWN,
};

export interface AutoLoginState {
  inProgress: boolean;
  result: string;
}

export const selectors = {
  getAutoLoginResult: (state: AutoLoginState) => state.result,
  isAutologinInProgress: (state: AutoLoginState) => state.inProgress,
};

const reducer = createReducer<AutoLoginState>(initialState, (builder) => {
  builder
    .addCase(actionCreators.setAutoLoginResult, (state, { payload }) => {
      state.inProgress = false;
      state.result = payload;
    })
    .addCase(actionCreators.startAutologin, (state) => {
      state.inProgress = true;
    });
});

export default reducer;
