import React, {
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react";
import emptyFunction from "fbjs/lib/emptyFunction";
import PropTypes from "prop-types";
import { emitEvent } from "@analytics/emit";
import { BI_LANDING_FIELD, EventFields, EventNames } from "@analytics/enums";
import { currentTimeMillis } from "src/utils/dateUtils";
import usePageVisibility from "ui/hooks/usePageVisibility";

const ScreenViewReportingContext = createContext({
  enterScreen: emptyFunction,
  exitScreen: emptyFunction,
  getStack: () => [],
});

export const emitView = (
  { reportedName },
  { reportedName: prevReportedName = "" },
  reason,
  additionalParams = {}
) => {
  emitEvent(EventNames.SCREEN_VIEW, {
    [EventFields.TANGO_SCREEN]: reportedName,
    [EventFields.PREVIOUS_SCREEN_NAME]: prevReportedName || "",
    [EventFields.REASON]: reason || "user",
    ...additionalParams,
    [BI_LANDING_FIELD.TRACKING_ID]: additionalParams?.tracking_id,
  });
};

export default ScreenViewReportingContext;

export const ScreenViewReportingContextProvider = ({ children }) => {
  const stateRef = useRef({ stack: [], lastDismissed: {} });

  const isPageVisible = usePageVisibility();
  useEffect(() => {
    const state = stateRef.current;
    if (!state.stack.length) {
      return;
    }
    const first = state.stack[0];
    if (isPageVisible) {
      // check to prevent double screen_view on initialization
      state.backgrounded && emitView(first, state.backgrounded, "fg");
    } else {
      state.backgrounded = first;
    }
  }, [isPageVisible]);

  const providerValue = useMemo(
    () => ({
      getStack: () => stateRef.current.stack,
      enterScreen: ({ nested, name, additionalParams }) => {
        const state = stateRef.current;
        if (nested && !state.stack.length) {
          // eslint-disable-next-line no-console
          console.error(
            `Nested screen with name '${name}' is shown without preceding root screen`
          );
        }
        const event = {
          name,
          reportedName:
            nested && state.stack.length
              ? `${state.stack[0].reportedName}.${name}`
              : name,
          startTimestamp: currentTimeMillis(),
        };
        state.stack = [event, ...state.stack];
        emitView(
          event,
          (state.stack.length > 1 ? state.stack[1] : state.lastDismissed) || {},
          "user",
          additionalParams
        );
      },
      exitScreen: ({ name, additionalParams }) => {
        const state = stateRef.current;
        const indexOfItem = state.stack.findIndex((x) => x.name === name);
        if (indexOfItem < 0) {
          // eslint-disable-next-line no-console
          console.warn(
            `Cannot find ${name} in stack: [${state.stack
              .map((x) => x.name)
              .join(", ")}]`
          );

          return;
        }
        const itemsToRemove = state.stack.slice(0, indexOfItem + 1);
        const rest = state.stack.slice(indexOfItem + 1);
        state.lastDismissed = itemsToRemove[0];
        state.stack = rest;
        if (state.stack.length) {
          const newTop = state.stack[0];
          newTop.startTimestamp = currentTimeMillis();
          emitView(newTop, state.lastDismissed, "user", additionalParams);
        }
      },
    }),
    []
  );

  return (
    <ScreenViewReportingContext.Provider value={providerValue}>
      {children}
    </ScreenViewReportingContext.Provider>
  );
};

ScreenViewReportingContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export const ScreenViewReporter = ({
  nested = false,
  name,
  additionalParams = {},
}) => {
  const { enterScreen, exitScreen } = useContext(ScreenViewReportingContext);
  useLayoutEffect(() => {
    enterScreen({ nested, name, additionalParams });

    return () => exitScreen({ nested, name, additionalParams });
  }, []);

  return null;
};

ScreenViewReporter.propTypes = {
  nested: PropTypes.bool,
  name: PropTypes.string,
  // eslint-disable-next-line
  additionalParams: PropTypes.object,
};
