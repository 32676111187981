import {
  type PayloadAction,
  SerializedError,
  createSlice,
} from "@reduxjs/toolkit";
import {
  ReferredUser,
  ReferredUsersMetaData,
} from "src/features/referralProgram/common/types";
import { loadReferralUsersList } from "src/features/referralProgram/state/asyncActions";
import { Nullable } from "src/types/common";

export interface ReferralProgramState {
  error: Nullable<SerializedError>;
  loading: boolean;
  meta: ReferredUsersMetaData;
  referredUsers: Array<ReferredUser>;
  searchValue: string;
}

const initialState: ReferralProgramState = {
  referredUsers: [],
  meta: {
    currentPage: 1,
    pageCount: 1,
    pageSize: 16,
    totalEarnedPoints: 0,
    totalNewUsers: 0,
    totalPoints: 0,
    totalRedeemPoints: 0,
    totalUsers: 0,
  },
  loading: false,
  error: null,
  searchValue: "",
};

const slice = createSlice({
  name: "referralProgramSlice",
  initialState,
  reducers: {
    setSearchValue(state, action: PayloadAction<string>) {
      state.searchValue = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadReferralUsersList.fulfilled, (state, action) => {
      state.loading = false;
      state.referredUsers = action.payload.data;
      state.meta = action.payload.meta;
    });
    builder.addCase(loadReferralUsersList.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(loadReferralUsersList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const { setSearchValue } = slice.actions;

export const referralProgramReducer = slice.reducer;
