import invariant from "fbjs/lib/invariant";
import AnalyticsUtils from "@analytics/AnalyticsUtils";
import { emitEvent } from "@analytics/emit";
import {
  BI_LANDING_FIELD,
  EventFields,
  EventNames,
  PERSONAL_OFFERS_SOURCE,
  PURCHASE_ORIGIN,
  PURCHASE_SOURCE,
  UI_COMPONENT,
} from "@analytics/enums";
import selectTicketPrivateParams from "@analytics/gifting/selectTicketPrivateParams";
import { fetchStreamInfo, leaveStream, sendMessageToStream } from "api/stream";
import { REFILL } from "enums/cashier";
import { FROM_FLOW } from "enums/modalDismissReason";
import {
  OneClickEndReason,
  OneClickEndResult,
  OneClickOfferTypes,
} from "enums/oneClickOffers";
import { ModalType, SendStreamMessageResponseCode } from "src/enums";
import {
  getOneClickPurchaseEnabled,
  getOneClickV2PurchaseEnabled,
  getPersonalOffersCashierRefillEnabled,
} from "state/abTests";
import {
  hideBottomScreen,
  openRefillDrawerBottomScreen,
  openRefillV2DrawerBottomScreen,
} from "state/actionCreators/bottomScreen";
import { receivedLiveRichNotification } from "state/actionCreators/connectionManager";
import {
  dismissModalWithType,
  openBuyCoinsModal,
  openRefillModal,
} from "state/actionCreators/modal";
import { genericNetworkError } from "state/actionCreators/networkError";
import { updatedLiveStreamInfo } from "state/actionCreators/streamInfo";
import {
  didSendMessage,
  failedToSendMessage,
  leftViewerSession,
  willBuyTicket,
  willSendMessage,
} from "state/actionCreators/viewerSession";
import loadShopOffers from "state/flows/loadShopOffers";
import { executeBuyTicket } from "state/flows/utils/executeBuyTicket";
import { loadClosestPersonalOffer } from "state/flows/utils/loadClosestPersonalOffer";
import {
  getCurrentStream,
  loginSelectors,
  shopSelectors,
  streamsCacheSelectors,
  upgradedStreamsSelectors,
  userSelectors,
  viewerSessionSelectors,
} from "state/selectors";
import retryAfterCaptcha from "./utils/retryAfterCaptcha";
export { default as initializeViewerSession } from "./manageViewerSession/initializeViewerSession";

export const findOfferByTicketPrice = (offers, ticketPrice) =>
  offers.find(
    ({ credits, bonusWithoutInitPurchase }) =>
      !bonusWithoutInitPurchase && credits >= ticketPrice
  );

export const buyTicketAndEnter =
  ({ afterSuccessfulPayment, isMobile, onPaymentSuccess } = {}) =>
  (dispatch, getState) => {
    const state = getState();
    invariant(
      loginSelectors.isLoggedIn(state),
      "buyTicketAndEnter: must be logged in!"
    );
    const streamId = viewerSessionSelectors.getStreamId(state);
    const stream = streamsCacheSelectors.getStreamById(state, streamId);
    const freeEntranceAmount =
      stream?.restrictions?.remain?.gift?.abonnement?.amount;

    invariant(
      streamId,
      `buyTicketAndEnter: active viewer session is required!`
    );
    const balance = userSelectors.getCoinsBalance(state);
    const ticketPrice = getCurrentStream(state).ticketPrice;
    const analyticEvents = selectTicketPrivateParams(state);
    const isPersonalOffersCashierRefillEnabled =
      getPersonalOffersCashierRefillEnabled(state);

    const totalBalance = userSelectors.getCoinsTotal(state);
    const isClientRegularPurchaser = totalBalance > 0;

    const isOneClickPurchaseEnabled = getOneClickPurchaseEnabled(state);
    const isOneClickV2PurchaseEnabled = getOneClickV2PurchaseEnabled(state);

    const isOneClickFlowEnabled =
      isPersonalOffersCashierRefillEnabled &&
      (isOneClickPurchaseEnabled || isOneClickV2PurchaseEnabled);

    // If client is a non-purchaser, get a default campaign
    const oneClickOfferType = isClientRegularPurchaser
      ? OneClickOfferTypes.ONE_CLICK_PREMIUM_IN_STREAM_REGULAR_PURCHASE
      : "";

    let oneClickV2PurchaseAnalyticsArgs = {
      [EventFields.STREAM_ID]: streamId,
      [EventFields.ONE_CLICK_TYPE]: oneClickOfferType,
    };

    const onSuccess = () => {
      onPaymentSuccess();
      dispatch(willBuyTicket());
      dispatch(
        executeBuyTicket(streamId, analyticEvents, isOneClickFlowEnabled)
      );
      dispatch(
        isMobile
          ? hideBottomScreen()
          : dismissModalWithType({
              modalType: ModalType.BUY_COINS,
              modalDismissReason: FROM_FLOW,
            })
      );

      if (isOneClickFlowEnabled) {
        emitEvent(EventNames.ONE_CLICK_END, {
          [EventFields.RESULT]: OneClickEndResult.SUCCESSFUL,
          ...oneClickV2PurchaseAnalyticsArgs,
        });
      }
    };

    const onError = () => {
      if (isOneClickFlowEnabled) {
        emitEvent(EventNames.ONE_CLICK_END, {
          [EventFields.RESULT]: OneClickEndResult.UNSUCCESSFUL,
          [EventFields.REASON]: OneClickEndReason.PURCHASE_FAILED,
          ...oneClickV2PurchaseAnalyticsArgs,
        });
      }
    };

    const onDismiss = () => {
      if (isOneClickFlowEnabled) {
        emitEvent(EventNames.ONE_CLICK_END, {
          [EventFields.RESULT]: OneClickEndResult.UNSUCCESSFUL,
          [EventFields.REASON]: OneClickEndReason.PURCHASE_REFUSED,
          ...oneClickV2PurchaseAnalyticsArgs,
        });
      }
    };

    const openCoinsModal = (offer, params = {}) => {
      if (isMobile) {
        const data = {
          screenData: {
            purchaseSource: PURCHASE_SOURCE.PAID_ENTRY,
            viewType: REFILL,
            uiComponent: UI_COMPONENT.REFILL,
            onSuccess,
            onError,
            onDismiss,
            offer,
            shouldDismissOnBack: !!offer,
            ...params,
          },
        };

        if (offer) {
          dispatch(openRefillDrawerBottomScreen(data));
        } else {
          dispatch(openRefillV2DrawerBottomScreen(data));
        }

        return;
      }

      const data = {
        purchaseSource: PURCHASE_SOURCE.PAID_ENTRY,
        viewType: REFILL,
        uiComponent: UI_COMPONENT.REFILL,
        isBackAvailable: !offer,
        offer,
        onSuccess,
        onError,
        onDismiss,
        dismissOnSuccess: true,
        ...params,
      };

      if (offer) {
        dispatch(openBuyCoinsModal(data));
      } else {
        dispatch(openRefillModal(data));
      }
    };

    if (balance < ticketPrice && !freeEntranceAmount) {
      if (afterSuccessfulPayment) {
        onSuccess();

        return;
      }

      if (isOneClickFlowEnabled) {
        return loadClosestPersonalOffer({
          giftPrice: ticketPrice,
          userBalance: balance,
          state,
          analyticsParams: oneClickV2PurchaseAnalyticsArgs,
          ...(isOneClickV2PurchaseEnabled && {
            offerType: oneClickOfferType,
            source: isClientRegularPurchaser
              ? PERSONAL_OFFERS_SOURCE.ONE_CLICK_PREMIUM_IN_STREAM_REGULAR_PURCHASE
              : PERSONAL_OFFERS_SOURCE.WEB_ONE_CLICK,
          }),
        }).then((offerCampaign) => {
          if (
            offerCampaign &&
            Array.isArray(offerCampaign.pricePoints) &&
            offerCampaign.pricePoints[0]
          ) {
            const closestOfferToBuyGift = offerCampaign.pricePoints[0];

            oneClickV2PurchaseAnalyticsArgs = {
              ...oneClickV2PurchaseAnalyticsArgs,
              [BI_LANDING_FIELD.PRICE_POINT_ID]: closestOfferToBuyGift.id,
              [BI_LANDING_FIELD.MARKET_OFFER_ID]:
                closestOfferToBuyGift.offers[0].marketOfferId,
              [BI_LANDING_FIELD.TRIGGER_ID]: offerCampaign.triggerId,
              [BI_LANDING_FIELD.SERVER_OFFER_ID]: offerCampaign.personalOfferId,
            };

            openCoinsModal(closestOfferToBuyGift, {
              purchaseOrigin: isOneClickV2PurchaseEnabled
                ? oneClickOfferType
                : PURCHASE_ORIGIN.WEB_ONE_CLICK,
              analyticsParams: oneClickV2PurchaseAnalyticsArgs,
            });
          } else {
            openCoinsModal(null);
          }
        });
      }

      return dispatch(loadShopOffers).then(() => {
        const offers = shopSelectors.getListOfPurchasableItems(getState());
        const offer = findOfferByTicketPrice(offers, ticketPrice);

        openCoinsModal(offer);
      });
    }

    invariant(
      !upgradedStreamsSelectors.isTicketPurchaseInProgress(state),
      "buyTicketAndEnter: purchase in progress already!"
    );

    return dispatch(
      executeBuyTicket(streamId, analyticEvents, isOneClickFlowEnabled)
    );
  };

export const leaveViewerSession = (streamId) => (dispatch) => {
  invariant(streamId, "leaveViewerSession: stream id must be set!");
  AnalyticsUtils.updateInteractionId();
  dispatch(leftViewerSession(streamId));

  return leaveStream(streamId).catch((error) =>
    dispatch(genericNetworkError(error))
  );
};

export const processLiveRichNotification =
  (liveRichNotification) => (dispatch, getState) => {
    const state = getState();
    const streamId = viewerSessionSelectors.getStreamId(state);
    const broadcasterId = viewerSessionSelectors.getBroadcasterId(state);
    const myAccountId = userSelectors.getMyAccountId(state);
    if (liveRichNotification.streamId !== streamId) {
      return;
    }
    dispatch(
      receivedLiveRichNotification(liveRichNotification, {
        broadcasterId,
        streamId,
        myAccountId,
      })
    );
  };

let sendMessageIdBase = 0;
const successfulCodes = [
  SendStreamMessageResponseCode.OK,
  SendStreamMessageResponseCode.CENSORED,
  SendStreamMessageResponseCode.QUOTA_EXCEEDED,
  SendStreamMessageResponseCode.FAILED_TO_SEND,
];
export const resetMessageIdBase = () => (sendMessageIdBase = 0); // for testing
export const sendMessage = (message) => async (dispatch, getState) => {
  const state = getState();
  const streamId = viewerSessionSelectors.getStreamId(state);
  const streamerId = viewerSessionSelectors.getBroadcasterId(state);
  invariant(message, `sendMessage: message must be provided!`);
  invariant(streamId, `sendMessage: active viewer session is required!`);
  invariant(
    loginSelectors.isLoggedIn(state),
    `sendMessage: user must be logged in`
  );
  const requestId = sendMessageIdBase++;
  const currentUserId = userSelectors.getMyAccountId(state);
  dispatch(willSendMessage({ message, streamId, currentUserId, requestId }));
  try {
    const code = await sendMessageToStream({
      message,
      streamId,
      streamerId,
      id: requestId,
    });
    if (successfulCodes.includes(code)) {
      dispatch(
        didSendMessage({
          streamId,
          currentUserId,
          requestId,
          paywall: code === SendStreamMessageResponseCode.QUOTA_EXCEEDED,
          censored: code === SendStreamMessageResponseCode.CENSORED,
          failedToSend: code === SendStreamMessageResponseCode.FAILED_TO_SEND,
        })
      );
    } else {
      throw code;
    }
  } catch (error) {
    dispatch(
      failedToSendMessage({
        streamId,
        currentUserId,
        requestId,
        paywall: error === SendStreamMessageResponseCode.PAYWALL,
        error,
      })
    );
    dispatch(genericNetworkError(error));
    retryAfterCaptcha(error, sendMessage(message));

    throw error;
  }
};

export const loadBatchStreamsInfo =
  (streamIds) => async (dispatch, getState) => {
    const state = getState();
    if (!streamIds?.length) {
      return;
    }
    await Promise.all(
      streamIds
        .map((id) => {
          const existingStreamInfo = streamsCacheSelectors.getStreamById(
            state,
            id
          );
          if (existingStreamInfo) {
            return null;
          }

          return fetchStreamInfo({ streamId: id }).then((info) =>
            dispatch(updatedLiveStreamInfo({ streamId: id, info }))
          );
        })
        .filter((x) => x)
    );
  };
