import {
  ReferredUser,
  ReferredUsersMetaData,
} from "src/features/referralProgram/common/types";

export const META_DATA_MOCK: ReferredUsersMetaData = {
  totalUsers: 1,
  totalNewUsers: 1,
  pageCount: 1,
  currentPage: 1,
  pageSize: 16,
  totalPoints: 10000,
  totalRedeemPoints: 5000,
  totalEarnedPoints: 500,
};

export const USER_DATA_MOCK: ReferredUser = {
  accountId: "FtG2NlKG-1zUJQ76hu6-nQ",
  points: 1000,
  redeemPoints: 5000,
  earnedPoints: 500,
  verified: true,
  active: true,
  new: true,
  firstName: "John Doe",
  profilePictureUrl: "https://resources.tango.me/guest_avatars/2/Cat.png",
  profileThumbnailUrl: "https://resources.tango.me/guest_avatars/2/Cat.png",
};
