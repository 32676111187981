import { AutologinResult } from "src/enums";
import { LOGOUT_END } from "src/features/signin/exports/state/actionTypes";
import { AUTO_LOGIN_RESULT } from "state/actionTypes";

export const makeWithUserSessionScope =
  (transform) => (reducer) => (state, action) => {
    if (
      (action.type === LOGOUT_END && !action.error) ||
      (action.type === AUTO_LOGIN_RESULT &&
        action.result === AutologinResult.AUTO_LOGIN_RESULT_SUCCESS)
    ) {
      return transform({ reducer, state, action });
    }

    return reducer(state, action);
  };

const defaultImpl = makeWithUserSessionScope(({ reducer, action }) =>
  reducer(undefined, action)
);

export default defaultImpl;
