import { shallowEqual, useSelector } from "react-redux";
import { getNftAuctionAllowedIds } from "environment";
import { getNftAuctionEnabled, getNftMarketplaceEnabled } from "state/abTests";
import { useAccountIdMatch } from "ui/hooks/useFeatureEnabled";

const selector = (state) => ({
  auctionEnabled: getNftAuctionEnabled(state),
  marketplaceEnabled: getNftMarketplaceEnabled(state),
});

export default () => {
  const { auctionEnabled, marketplaceEnabled } = useSelector(
    selector,
    shallowEqual
  );
  const canSeeAuction = useAccountIdMatch(getNftAuctionAllowedIds);

  return (auctionEnabled || marketplaceEnabled) && canSeeAuction;
};
