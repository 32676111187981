import { getTTQId } from "environment";
import { loadScript } from "src/utils/scriptUtils";

export default () => {
  const id = getTTQId();
  if (!id) {
    return;
  }
  const src = "https://analytics.tiktok.com/i18n/pixel/events.js";

  const init = function () {
    window.TiktokAnalyticsObject = "ttq";
    window.ttq = window.ttq || [];
    const ttq = window.ttq;
    ttq.methods = [
      "page",
      "track",
      "identify",
      "instances",
      "debug",
      "on",
      "off",
      "once",
      "ready",
      "alias",
      "group",
      "enableCookie",
      "disableCookie",
    ];
    ttq.setAndDefer = function (t, method) {
      t[method] = function (...rest) {
        t.push([method, ...rest]);
      };
    };
    ttq.methods.forEach((method) => ttq.setAndDefer(ttq, method));

    ttq.instance = function (t) {
      const e = ttq._i[t];
      ttq.methods.forEach((method) => ttq.setAndDefer(e, method));
      return e;
    };

    ttq._i = ttq._i || {};
    ttq._i[id] = [];
    ttq._i[id]._u = src;
    ttq._t = ttq._t || {};
    ttq._t[id] = +new Date();
    ttq._o = ttq._o || {};
    ttq._o[id] = {};

    ttq.page();
  };
  init();

  loadScript({
    src: `${src}?sdkid=${id}&lib=ttq`,
    nodeId: "ttq-script",
    async: true,
    delay: 5000,
  });
};
