import { batch } from "react-redux";
import { Dispatch } from "redux";
import {
  setPremiumMessageDetails,
  unlockPremiumMessageEnd,
} from "src/features/chat/premiumMessage/state/slice";
import {
  ContentStatus,
  PremiumMessageDetails,
  PremiumMessageDetailsMap,
} from "src/features/chat/premiumMessage/types";

export const updateUnlockDetails = (
  oldDetailsMap: PremiumMessageDetailsMap,
  newDetails: PremiumMessageDetails[],
  dispatch: Dispatch
) => {
  const detailsMap = newDetails.reduce(
    (detailMap, newDetail) => {
      detailMap[newDetail.messageId] = newDetail;
      return detailMap;
    },
    { ...oldDetailsMap }
  );

  const unlockedDetails = newDetails.filter((newDetail) => {
    const oldDetail = oldDetailsMap[newDetail.messageId];

    return (
      oldDetail?.status !== ContentStatus.UNLOCKED &&
      newDetail.status === ContentStatus.UNLOCKED
    );
  });

  batch(() => {
    unlockedDetails.forEach(({ messageId }) => {
      dispatch(unlockPremiumMessageEnd({ messageId }));
    });

    dispatch(setPremiumMessageDetails(detailsMap));
  });
};
