import once from "lodash.once";
import {
  getAndroidAppId,
  getGetTheAppAppStoreUrl,
  getGetTheAppGooglePlayUrl,
  getIosAppId,
} from "environment";
import { APPS_FLYER_ONE_LINK_URL } from "src/core/analytics/constants";
import { AppsFlyerPlatform } from "src/core/analytics/enums";

const oneLinkURL = "https://tango.onelink.me/RCIH";

const afParameters = {
  mediaSource: { keys: ["utm_source"], defaultValue: "Web_Organic" },
  deepLinkValue: { keys: ["deep_link_value"], defaultValue: "page_unknown" },
  button_name: {
    paramKey: "button_name",
    keys: ["button_name"],
    defaultValue: "button_unknown",
  },
  campaign: { keys: ["utm_campaign"], defaultValue: "Web_Campaign" },
  afSub1: { keys: ["utm_content"], defaultValue: "Web_Content" },
  afSub2: { keys: ["utm_medium"], defaultValue: "Web_Medium" },
  afSub3: { keys: ["utm_term"], defaultValue: "Web_Term" },
  afSub4: { keys: ["utm_id"], defaultValue: "Web_ID" },
  af_dp: {
    paramKey: "af_dp",
    keys: ["af_dp"],
    defaultValue: "tango%3A%2F%2F",
  },
  custom_ss_ui: { paramKey: "af_ss_ui", defaultValue: "true" },
  afCustom: [
    {
      paramKey: "af_force_deeplink",
      keys: ["af_force_deeplink"],
      defaultValue: "true",
    },
  ],
};

export const setAppsFlyerOneLinkURL = () => {
  const generatedOneLinkURL =
    window.AF_SMART_SCRIPT?.generateOneLinkURL({
      oneLinkURL,
      afParameters,
    })?.clickURL || "";

  localStorage.setItem(APPS_FLYER_ONE_LINK_URL, generatedOneLinkURL);
};

export const getAppsFlyerOneLinkURL = () =>
  localStorage.getItem(APPS_FLYER_ONE_LINK_URL);

const generateAppsFlyerDirectLinkURL = (
  platform: string,
  appid: string,
  redirectURL: string
) =>
  window.AF_SMART_SCRIPT?.generateDirectClickURL({
    afParameters,
    platform,
    app_id: appid,
    redirectURL,
  })?.clickURL || "";

export const handleGetTheAppGooglePlayUrl = once(
  () =>
    generateAppsFlyerDirectLinkURL(
      AppsFlyerPlatform.ANDROID,
      getAndroidAppId(),
      getGetTheAppGooglePlayUrl()
    ) || getGetTheAppGooglePlayUrl()
);

export const handleGetTheAppAppStoreUrl = once(
  () =>
    generateAppsFlyerDirectLinkURL(
      AppsFlyerPlatform.IOS,
      getIosAppId(),
      getGetTheAppAppStoreUrl()
    ) || getGetTheAppAppStoreUrl()
);
