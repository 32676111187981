import { getAuctionProfile } from "api/nft";
import { nftAuctionSelectors } from "state/selectors";
import { lotsActionCreators, profilesActionCreators } from "state/tree/nft";
import { ResponseCode } from "ui/nft/enums";

export default ({ accountId }) =>
  async (dispatch, getState) => {
    const state = getState();

    if (nftAuctionSelectors.isAuctionProfileLoading(state, accountId)) {
      return;
    }

    dispatch(profilesActionCreators.loadProfileStart({ accountId }));
    try {
      const {
        code,
        lots = [],
        creator,
        owned,
      } = await getAuctionProfile({
        accountId,
        includeNft: true,
      });

      if (code !== ResponseCode.OK) {
        throw new Error("Network error");
      }

      dispatch(
        profilesActionCreators.loadProfileEnd({
          accountId,
          lots,
          creatorNfts: creator,
          ownedNfts: owned,
        })
      );
      dispatch(lotsActionCreators.updateLots({ lots }));
    } catch (error) {
      dispatch(profilesActionCreators.loadProfileError({ error, accountId }));
    }
  };
