import { createReducer } from "@reduxjs/toolkit";
import { fetchSessionDetails } from "src/state/actionCreators/sessionDetails";
import { Nullable } from "src/types/common";
import {
  AsyncState,
  addAsyncCasesToBuilderV2,
  initialFetcherStateMeta,
} from "state/hor/addFetcherActionsToBuilder";

export const persistConfig = {
  whitelist: ["data"],
};

interface SessionDetails {
  expirationTsSec: number;
  sessionId: string;
}

export type SessionDetailsState = AsyncState<Nullable<SessionDetails>>;

export const initialSessionDetailsState = {
  data: null,
  meta: initialFetcherStateMeta,
};

export default createReducer<SessionDetailsState>(
  initialSessionDetailsState,
  (builder) => {
    addAsyncCasesToBuilderV2({
      initialData: initialSessionDetailsState.data,
      builder,
      action: fetchSessionDetails,
      prepareData: (oldData, newData) => newData,
    });
  }
);
