import { useCallback, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getNftAuctionLastHourStalePeriod,
  getNftAuctionStalePeriod,
} from "environment";
import { HOUR } from "src/constants";
import { getNftAuctionCurrentId } from "state/abTests";
import loadAuctionsList from "state/flows/nft/loadAuctionsList";
import { nftAuctionSelectors } from "state/selectors";
import { auctionsActionCreators } from "state/tree/nft";
import useInterval from "ui/hooks/useInterval";
import useAuctionEnabled from "ui/nft/hooks/useAuctionEnabled";

export const useAuctionStalePeriod = (callback, millisLeft) => {
  const stalePeriod = getNftAuctionStalePeriod();
  const lastHourStalePeriod = getNftAuctionLastHourStalePeriod();
  const interval =
    millisLeft && millisLeft < HOUR ? lastHourStalePeriod : stalePeriod;

  return useInterval(callback, interval);
};

const selector = (state) => ({
  auctionConfig: nftAuctionSelectors.getAuction(state),
  auctionConfigStale: nftAuctionSelectors.getAuctionStale(state),
  auctionCurrentId: getNftAuctionCurrentId(state),
});

export default ({ withRefresh = false }) => {
  const dispatch = useDispatch();
  const isAuctionEnabled = useAuctionEnabled();
  const { auctionConfig, auctionConfigStale, auctionCurrentId } = useSelector(
    selector,
    shallowEqual
  );

  useEffect(() => {
    isAuctionEnabled && auctionConfigStale && dispatch(loadAuctionsList());
  }, [isAuctionEnabled, auctionConfigStale, dispatch]);

  const setAuctionStale = useCallback(() => {
    if (!withRefresh || !isAuctionEnabled || !auctionCurrentId) {
      return;
    }

    dispatch(auctionsActionCreators.setAuctionStale());
  }, []);

  useAuctionStalePeriod(setAuctionStale, auctionConfig?.millisLeft);

  return auctionConfig;
};
