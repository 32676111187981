import { LOCATION_CHANGE } from "connected-react-router";
import emit, { EVENT, emitEvent } from "@analytics/emit";
import { EventNames } from "@analytics/enums";
import { getGaTrackingEnabled, getGaTrackingId } from "environment";
import { emitBIRegistrationSuccess } from "src/core/analytics/utils/emitBIRegistrationSuccess";
import { sendMarketingEventFlow } from "src/features/marketing/exports/state";
import { ANALYTICS } from "state/actionTypes";

const logRoute = (route) =>
  emit("config", getGaTrackingId(), { page_path: route });

export const createAnalyticsAction = ({
  eventType = EVENT,
  actionName,
  params = {},
}) => ({
  type: ANALYTICS,
  payload: {
    eventType,
    actionName,
  },
  meta: params,
});

export default ({ dispatch }) =>
  (next) =>
  (action) => {
    if (action.type === ANALYTICS) {
      const { eventType, actionName } = action.payload;
      if (eventType === EVENT) {
        emitEvent(actionName, action.meta);
      } else {
        emit(eventType, actionName, action.meta);
      }

      return;
    }
    if (action.type === LOCATION_CHANGE && getGaTrackingEnabled()) {
      logRoute(action.payload.location.pathname);
    }
    if (action.meta && action.meta.analytics) {
      const { eventType = EVENT, actionName, params } = action.meta.analytics;
      if (eventType === EVENT) {
        emitEvent(actionName, params);
        if (actionName === EventNames.REGISTRATION_COMPLETED) {
          dispatch(sendMarketingEventFlow(emitBIRegistrationSuccess));
        }
      } else {
        emit(eventType, actionName, params);
      }
    }

    return next(action);
  };
