import { SearchParams } from "@analytics/enums";
import { getTwitterRegistrationId } from "environment";
import { FBEventNames, FBMethods } from "src/core/analytics/enumsFB";
import {
  MicrosoftAdsEventFields,
  MicrosoftAdsEventNames,
  MicrosoftAdsMethods,
} from "src/core/analytics/enumsMSAds";
import {
  SnapchatEventFields,
  SnapchatEventNames,
  SnapchatMethods,
} from "src/core/analytics/enumsSnapchat";
import {
  TwitterEventFields,
  TwitterMethods,
} from "src/core/analytics/enumsTwitter";
import { GetUserHashedInfoResponse } from "src/features/marketing/exports/types";
import { omitUndefinedProps } from "utils/omitUndefinedProps";

export const emitBIRegistrationSuccess = (
  userHashedInfo: GetUserHashedInfoResponse,
  accountId: string
) => {
  const snapchatClickId = localStorage.getItem(SearchParams.SNAPCHAT);

  window.fbq?.(FBMethods.TRACK, FBEventNames.COMPLETED_REGISTRATION);

  window.twq?.(
    TwitterMethods.EVENT,
    getTwitterRegistrationId(),
    omitUndefinedProps({
      [TwitterEventFields.CLIENT_DEDUP_ID]: accountId,
      [TwitterEventFields.EMAIL_ADDRESS]: userHashedInfo?.email,
      [TwitterEventFields.PHONE_NUMBER]: userHashedInfo?.phoneNumber
        ? `+${userHashedInfo?.phoneNumber}`
        : undefined,
    })
  );

  window.snaptr?.(
    SnapchatMethods.TRACK,
    SnapchatEventNames.COMPLETED_REGISTRATION,
    {
      [SnapchatEventFields.CLIENT_DEDUP_ID]: accountId,
      [SnapchatEventFields.HASHED_PHONE]: userHashedInfo?.hashedPhoneNumber,
      [SnapchatEventFields.HASHED_EMAIL]: userHashedInfo?.hashedEmail,
      [SnapchatEventFields.CLICK_ID]: snapchatClickId,
    }
  );

  window.uetq?.push(
    MicrosoftAdsMethods.EVENT,
    MicrosoftAdsEventNames.REGISTRATION,
    {
      pid: {
        [MicrosoftAdsEventFields.EMAIL]: userHashedInfo?.email,
        [MicrosoftAdsEventFields.PHONE_NUMBER]: userHashedInfo?.phoneNumber
          ? `+${userHashedInfo?.phoneNumber}`
          : undefined,
      },
    }
  );
};
