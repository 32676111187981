import { Nullable } from "src/types/common";

export enum PostType {
  ALBUM = "ALBUM",
  PICTURE = "PICTURE",
  TEXT = "TEXT",
  VIDEO = "VIDEO",
  WEBLINK = "WEBLINK",
}

export enum PostTypeCode {
  ALBUM = 57,
  HAPPY_MOMENT = 62,
  PHOTO = 3,
  TEXT = 0,
  VIDEO = 1,
  WEBLINK = 50,
}

type PostWithContent<T extends PostType, C extends Record<string, unknown>> = {
  authorId: string;
  content: {
    postType: T;
  } & C;
  giftedPointsCount: number;
  isLikedByMe: boolean;
  likesCount: number;
  postId: string;
  subscriptionDetails: {
    streamer: {
      level: number;
      maxAvailableUnlockExclusivePostCount: number;
      streamerProfile: StreamerProfile;
    };
  };
  timeCreated: number;
  type: T;
  unlocked: boolean;
};

export interface StreamerProfile {
  accountId: string;
  firstName: Nullable<string>;
  lastName: Nullable<string>;
  pictureUrl: Nullable<string>;
  thumbnailUrl: Nullable<string>;
}

interface PictureAndThumbnail {
  blurLevel: number;
  blurredPictureUrl: string;
  blurredThumbnailUrl: string;
  pictureHeight: Nullable<number>;
  pictureUrl: string;
  pictureWidth: Nullable<number>;
  referenceUrl: string;
  thumbnailHeight: Nullable<number>;
  thumbnailUrl: string;
  thumbnailWidth: Nullable<number>;
}

export type PicturePost = PostWithContent<
  PostType.PICTURE,
  {
    attribution: string;
    blurLevel: number;
    blurredThumbnailUrl: string;
    blurredUrl: string;
    caption: string;
    hyperlinkCaption: string;
    hyperlinkUrl: string;
    referenceUrl: string;
    tapActionType: string;
    thumbnailHeight: Nullable<number>;
    thumbnailUrl: string;
    thumbnailWidth: Nullable<number>;
    url: string;
  }
>;

export type AlbumPost = PostWithContent<
  PostType.ALBUM,
  {
    caption: string;
    items: PictureAndThumbnail[];
  }
>;

export type VideoPost = PostWithContent<
  PostType.VIDEO,
  {
    blurLevel: number;
    blurredThumbnailUrl: string;
    caption: string;
    duration: number;
    height: number;
    mediaId: string;
    referenceUrl: string;
    rotation: number;
    thumbnailUrl: string;
    url: string;
    width: number;
  }
>;

export type TextPost = PostWithContent<
  PostType.TEXT,
  {
    text: string;
  }
>;

export type WeblinkPost = PostWithContent<
  PostType.WEBLINK,
  {
    caption: string;
    imageType: number;
    imageUrl: string;
    pageUrl: string;
    siteName: string;
    title: string;
  }
>;

export type Post = AlbumPost | PicturePost | TextPost | VideoPost | WeblinkPost;

export interface Feed {
  errorCode: number;
  errorMessage: string;
  nextCursor: string;
  posts: Post[];
  previousCursor: string;
}

export interface PostResponse {
  commentsCount: string;
  content: string;
  createdTimestamp: string;
  gifts: Gifts;
  likes: Likes;
  owner: Owner;
  postId: string;
  subscriptionDetails: SubscriptionDetails;
  type: number;
  unlocked: boolean;
}

export interface Owner {
  accountId: string;
  firstName: string;
  thumbnailUrl: string;
}

export interface SubscriptionDetails {
  streamer: Streamer;
}

export interface Streamer {
  level: number;
}

export interface Likes {
  lastLikers?: LastLiker[];
  likedByMe: boolean;
  totalCount: string;
}

export interface LastLiker {
  accountId: string;
  firstName: string;
  thumbnailUrl: string;
}

export interface Gifts {
  pointsCount: string;
}

export interface FeedResponseData {
  code: number;
  nextCursor: string;
  posts: PostResponse[];
  previousCursor: string;
}
