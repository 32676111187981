import React, { memo, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import type { ComponentWithClassName } from "src/types/common";
import { getProfileDeleteAccountEnabled } from "state/abTests";
import { openDeleteAccountModalHandler } from "state/actionCreators/modal";
import Button, { ButtonSize, ButtonVariant } from "ui/common/button/Button";
import { useBreakpointMobileLayout } from "ui/hooks/useBreakpoint";
import { ReactComponent as DeleteIcon } from "img/ic_delete.svg";
import styles from "ui/scenes/settings/contents/app/DeleteAccountButton.scss";

interface DeleteAccountButtonProps {
  withDivider?: boolean;
}

const DeleteAccountButton: ComponentWithClassName<DeleteAccountButtonProps> = ({
  withDivider,
  className,
}) => {
  const dispatch = useDispatch();
  const isMobile = useBreakpointMobileLayout();

  const isProfileDeleteAccountEnabled = useSelector(
    getProfileDeleteAccountEnabled
  );

  const handleClick = useCallback(() => {
    dispatch(openDeleteAccountModalHandler({ isMobile }));
  }, [dispatch, isMobile]);

  if (!isProfileDeleteAccountEnabled) {
    return null;
  }

  return (
    <>
      {withDivider && <div className={styles.divider} />}

      <Button
        data-testid="delete-account"
        size={ButtonSize.BIG_48}
        variant={ButtonVariant.SECONDARY}
        onClick={handleClick}
        leftIcon={DeleteIcon}
        className={classNames(
          styles.button,
          {
            [styles.mobile]: isMobile,
          },
          className
        )}
      >
        <FormattedMessage
          id="deleteAccount.button.label"
          defaultMessage="Delete Account"
        />
      </Button>
    </>
  );
};

export default memo(DeleteAccountButton);
