import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { CaseReducer } from "@reduxjs/toolkit/src/createReducer";
import { AnyAction } from "redux";
import { AutologinResult } from "src/enums";
import { LOGOUT_END } from "src/features/signin/exports/state/actionTypes";
import { AUTO_LOGIN_RESULT } from "state/actionTypes";

export default <State, Action extends AnyAction>(
  builder: Omit<ActionReducerMapBuilder<State>, "addCase">,
  reducer: CaseReducer<State, Action>
) => {
  builder.addMatcher(
    (action) =>
      (action.type === LOGOUT_END && !action.error) ||
      (action.type === AUTO_LOGIN_RESULT &&
        action.result === AutologinResult.AUTO_LOGIN_RESULT_SUCCESS),
    reducer
  );

  return builder;
};
