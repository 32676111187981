import { TOKEN_TYPE_INSTAGRAM } from "enums/socialNetworkTokenType";
import { omit } from "src/utils/miniLodash";
import {
  INSTAGRAM_IMAGES_LOADED,
  SOCIAL_NETWORK_TOKENS_REMOVE,
} from "state/actionTypes";

export const actionCreators = {
  loadedInstagramPosts: ({ accountId, images, timestamp }) => ({
    type: INSTAGRAM_IMAGES_LOADED,
    payload: images,
    meta: { accountId, timestamp },
  }),
};

export const selectors = {
  getInstagramImagesForUser: (state, accountId) =>
    state.posts[accountId]?.images,
  getCachedDataTimestampForUser: (state, accountId) =>
    state.posts[accountId]?.timestamp,
};

const initialState = { posts: {} };

export default (state = initialState, action) => {
  switch (action.type) {
    case INSTAGRAM_IMAGES_LOADED: {
      const { accountId, timestamp } = action.meta;
      return {
        ...state,
        posts: {
          ...state.posts,
          [accountId]: {
            images: action.payload,
            timestamp,
          },
        },
      };
    }
    case SOCIAL_NETWORK_TOKENS_REMOVE: {
      const { tokenType } = action.payload;
      const { accountId } = action.meta;
      if (tokenType !== TOKEN_TYPE_INSTAGRAM) {
        return state;
      }
      const existingData = state.posts[accountId];
      if (!existingData) {
        return state;
      }
      return {
        ...state,
        posts: omit(state.posts, accountId),
      };
    }
  }
  return state;
};
