import { SearchParams } from "@analytics/enums";
import {
  SnapchatEventFields,
  SnapchatEventNames,
  SnapchatMethods,
} from "src/core/analytics/enumsSnapchat";
import { GetUserHashedInfoResponse } from "src/features/marketing/exports/types";

export const emitBIPageViewSuccess = (
  userHashedInfo: GetUserHashedInfoResponse
) => {
  const snapchatClickId = localStorage.getItem(SearchParams.SNAPCHAT);

  window.snaptr?.(SnapchatMethods.TRACK, SnapchatEventNames.PAGE_VIEW, {
    [SnapchatEventFields.HASHED_PHONE]: userHashedInfo?.hashedPhoneNumber,
    [SnapchatEventFields.HASHED_EMAIL]: userHashedInfo?.hashedEmail,
    [SnapchatEventFields.CLICK_ID]: snapchatClickId,
  });
};
