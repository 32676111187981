import { fetchPurchasableBundles } from "api/shop";

export default ({ actionCreators, selectors }) =>
  (version, force = false) =>
  async (dispatch, getState) => {
    const state = getState();
    const isStale =
      selectors.getIsStale(state) ||
      version !== selectors.getVersion(state) ||
      force;
    if (!isStale || selectors.getIsLoadInProgress(state)) {
      return;
    }
    dispatch(actionCreators.fetchBegan());
    try {
      const json = await fetchPurchasableBundles({ version });
      dispatch(
        actionCreators.fetchCompleted({
          data: json,
          replaceAll: true,
          version,
        })
      );
    } catch (error) {
      dispatch(
        actionCreators.fetchFailed({ error, removeAll: false, version })
      );
    }
  };
