import fetch from "api/utils/enhancedFetch";
import { getProxycadorHost } from "environment";
import { DEFAULT_REFERRED_USERS_LIST_REQUEST } from "src/features/referralProgram/common/constants";
import { ReferredUsersResponseCode } from "src/features/referralProgram/common/enums";
import {
  META_DATA_MOCK,
  USER_DATA_MOCK,
} from "src/features/referralProgram/common/mocks";
import { ReferredUsersListResponse } from "src/features/referralProgram/common/types";

// TODO: use mock data until CSDEV-7215 is delivered
const USE_MOCK_DATA = true;

export const loadReferredUsersListRequest = (
  request = DEFAULT_REFERRED_USERS_LIST_REQUEST
): Promise<ReferredUsersListResponse> => {
  if (USE_MOCK_DATA) {
    return Promise.resolve({
      data: [USER_DATA_MOCK],
      meta: META_DATA_MOCK,
      responseCode: ReferredUsersResponseCode.SUCCESS,
    });
  }

  return fetch(`${getProxycadorHost()}/v2/referrals/statistics`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      accept: "application/json",
    },
    body: JSON.stringify(request),
  }).then((resp) => resp.json());
};
