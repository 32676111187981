import React, { FC, memo } from "react";
import { VideoSource } from "src/types/gift";
import { GiftVideoProps } from "src/ui/animations/GiftVideo";
import styles from "./GiftChatVideo.scss";

const ChatGiftVideo: FC<GiftVideoProps> = ({ sources, onComplete }) => {
  if (!sources.length) {
    return null;
  }

  return (
    <video
      className={styles.root}
      autoPlay
      muted
      playsInline
      onEnded={onComplete}
      disablePictureInPicture
    >
      {sources.map((source: VideoSource) => (
        <source key={source.src} src={source.src} type={source.type} />
      ))}
    </video>
  );
};

ChatGiftVideo.displayName = "ChatGiftVideo";

export default memo(ChatGiftVideo);
