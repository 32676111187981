import React, { useCallback, useMemo, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import PropTypes from "prop-types";
import { SCREEN_NAME } from "@analytics/enums";
import { ScreenViewReporter } from "@analytics/screenView/ScreenViewReportingContext";
import { getNftAuctionRemoveLotEnabled } from "environment";
import { getNftAuctionCurrentId } from "state/abTests";
import { openRemoveNftCardModalBottomScreen } from "state/actionCreators/bottomScreen";
import { openRemoveNftCardModal } from "state/actionCreators/modal";
import { settingsSelectors, userSelectors } from "state/selectors";
import Spinner from "ui/common/Spinner";
import Button, { ButtonSize, ButtonVariant } from "ui/common/button/Button";
import sharedMessages from "ui/common/intl/sharedMessages";
import { useBreakpointMobileLayout } from "ui/hooks/useBreakpoint";
import useLoadLotsByAccountId from "ui/hooks/useLoadLotsByAccountId";
import { useLoadSettings } from "ui/hooks/useLoadSettings";
import useAuctionEnabled from "ui/nft/hooks/useAuctionEnabled";
import DeleteAccountButton from "ui/scenes/settings/contents/app/DeleteAccountButton";
import Options from "ui/scenes/settings/contents/app/Options";
import {
  FEED_KEY,
  LEADERBOARD_KEY,
  NSFW_KEY,
  PREMIUM_KEY,
  SEARCH_KEY,
} from "ui/scenes/settings/contents/app/SettingsEnum";
import ContentTitle from "../ContentTitle";
import styles from "./AppSettingsContent.scss";

export const allOptionKeys = [
  NSFW_KEY,
  PREMIUM_KEY,
  FEED_KEY,
  SEARCH_KEY,
  LEADERBOARD_KEY,
];
const optionKeys = [PREMIUM_KEY, FEED_KEY, SEARCH_KEY, LEADERBOARD_KEY];

const selector = (state) => ({
  accountId: userSelectors.getMyAccountId(state),
  auctionCurrentId: getNftAuctionCurrentId(state),
  isLoadInProgress: settingsSelectors.getIsLoadInProgress(state),
});

const AppSettingsContent = ({ className }) => {
  const isAuctionEnabled = useAuctionEnabled();
  const isAuctionRemoveLotEnabled = getNftAuctionRemoveLotEnabled();
  const { accountId, auctionCurrentId, isLoadInProgress } = useSelector(
    selector,
    shallowEqual
  );
  const isMobile = useBreakpointMobileLayout();
  const { lots } = useLoadLotsByAccountId(accountId);
  const auctionLot = useMemo(
    () =>
      isAuctionEnabled
        ? lots.find((lot) => lot?.auctionId === auctionCurrentId)
        : null,
    [isAuctionEnabled, lots]
  );
  const dispatch = useDispatch();
  const optionsRef = useRef();

  useLoadSettings();

  const removeLot = useCallback(() => {
    isMobile
      ? dispatch(
          openRemoveNftCardModalBottomScreen({
            confirm: false,
            accountId,
            lotId: auctionLot?.id,
          })
        )
      : dispatch(
          openRemoveNftCardModal({
            confirm: false,
            accountId,
            lotId: auctionLot?.id,
          })
        );
  }, [isMobile, dispatch, accountId, auctionLot?.id]);

  return (
    <div
      className={classnames(styles.root, className)}
      data-testid="settings-content"
    >
      <ScreenViewReporter name={SCREEN_NAME.MOBILE_APP_SETTING} />
      <ContentTitle>
        <FormattedMessage {...sharedMessages.settings} />
      </ContentTitle>

      {isLoadInProgress ? (
        <Spinner />
      ) : (
        <>
          <Options ref={optionsRef} options={optionKeys} />

          <DeleteAccountButton withDivider />
        </>
      )}

      {isAuctionRemoveLotEnabled && isAuctionEnabled && auctionLot && (
        <div className={styles.nftSetting}>
          <h2 className={styles.title}>
            <FormattedMessage id="tango_cards" defaultMessage="Tango Cards" />
          </h2>
          <p className={styles.description}>
            <FormattedMessage
              id="tango_cards_settings_text"
              defaultMessage="Your unique Tango Card is available for bidding in the Auction."
            />
          </p>
          <Button
            size={ButtonSize.BIG_48}
            variant={ButtonVariant.SECONDARY}
            className={styles.button}
            onClick={removeLot}
          >
            <FormattedMessage
              id="tango_cards_settings_cta"
              defaultMessage="Remove my Card from Auction"
            />
          </Button>
        </div>
      )}
    </div>
  );
};

AppSettingsContent.propTypes = {
  className: PropTypes.string,
};

export default AppSettingsContent;
