import tangoAnalytics from "src/core/analytics";

import { EventFields, StorageKeys } from "src/core/analytics/enums";
import { GetUserHashedInfoResponse } from "src/features/marketing/api/marketing";
import { loadUserHashedInfo } from "src/features/marketing/state/asyncAction";
import { marketingSelectors } from "src/features/marketing/state/selectors";
import { userSelectors } from "state/selectors";
import { ApplicationThunk } from "state/types";

type BIEventCallback = (
  userHashedInfoResponse: GetUserHashedInfoResponse,
  accountId: string
) => void;

export const sendMarketingEventFlow =
  (emitMarketingBIEvent: BIEventCallback): ApplicationThunk =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      const analyticsKey = marketingSelectors.getAnalyticsKey(state);
      const userHashedInfo = marketingSelectors.getUserHashedInfo(state);
      const myAccountId = userSelectors.getMyAccountId(state);
      const deduplicationKey = analyticsKey || myAccountId;

      if (!userHashedInfo?.hashedPhoneNumber && !userHashedInfo?.hashedEmail) {
        const userHashedInfoResponse =
          await dispatch(loadUserHashedInfo()).unwrap();

        tangoAnalytics.then((analytics) =>
          analytics.Local.setItem(StorageKeys.ROUTE_DATA, {
            [EventFields.HASHE]: userHashedInfoResponse?.hashedEmail,
            [EventFields.HASHP]: userHashedInfoResponse?.hashedPhoneNumber,
          })
        );

        emitMarketingBIEvent(userHashedInfoResponse, deduplicationKey);
      } else {
        emitMarketingBIEvent(userHashedInfo, deduplicationKey);

        tangoAnalytics.then((analytics) =>
          analytics.Local.setItem(StorageKeys.ROUTE_DATA, {
            [EventFields.HASHE]: userHashedInfo?.hashedEmail,
            [EventFields.HASHP]: userHashedInfo?.hashedPhoneNumber,
          })
        );
      }
    } catch (err) {}
  };
