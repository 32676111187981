import { useCallback, useEffect, useLayoutEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import loadAuctionProfile from "state/flows/nft/loadAuctionProfile";
import { nftAuctionSelectors } from "state/selectors";
import { profilesActionCreators } from "state/tree/nft";
import { LotStatus } from "ui/nft/enums";
import { useAuctionStalePeriod } from "ui/nft/hooks/useAuction";

const selectorFactory = (accountId) => (state) => ({
  auctionProfile: nftAuctionSelectors.getAuctionProfile(state, accountId),
  isLoading: nftAuctionSelectors.isAuctionProfileLoading(state, accountId),
  lots:
    nftAuctionSelectors
      .getAuctionProfile(state, accountId)
      ?.lots?.reduce((acc, lotId) => {
        const lot = nftAuctionSelectors.getLotById(state, lotId);
        if (
          !lot ||
          lot.status === LotStatus.LOT_FINISHED ||
          lot.millisLeft <= 0
        ) {
          return acc;
        }
        acc.push(lot);
        return acc;
      }, []) || [],
  creatorNfts: nftAuctionSelectors.getAuctionProfile(state, accountId)
    ?.creatorNfts,
  ownedNfts: nftAuctionSelectors.getAuctionProfile(state, accountId)?.ownedNfts,
});

export const useLoadLotsByAccountId = (accountId) => {
  const dispatch = useDispatch();
  const selector = useMemo(() => selectorFactory(accountId), [accountId]);
  const { auctionProfile, lots, creatorNfts, ownedNfts, isLoading } =
    useSelector(selector);

  const lowestMillisLeft = useMemo(
    () => [...lots].sort((a, b) => a.millisLeft - b.millisLeft)[0]?.millisLeft,
    [lots]
  );

  const setProfileStale = useCallback(() => {
    if (lots.length) {
      dispatch(profilesActionCreators.setProfileStale({ accountId }));
    }
  }, [accountId, lots.length]);

  useAuctionStalePeriod(setProfileStale, lowestMillisLeft);

  useLayoutEffect(() => {
    dispatch(loadAuctionProfile({ accountId }));
  }, [accountId]);

  useEffect(() => {
    if (auctionProfile?.stale) {
      dispatch(loadAuctionProfile({ accountId }));
    }
  }, [auctionProfile?.stale]);

  return { lots, creatorNfts, ownedNfts, isLoading };
};

export default useLoadLotsByAccountId;
