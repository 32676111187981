import { HTTP_CODE_ERROR, HTTP_CODE_SUCCESS } from "enums/httpCodes";

export enum ReferredUsersResponseCode {
  SERVER_ERROR = HTTP_CODE_ERROR,
  SUCCESS = HTTP_CODE_SUCCESS,
}

export enum ReferredUsersOrder {
  ASC = "asc",
  DESC = "dsc",
}
