import React, { memo, useMemo } from "react";
import { DEFAULT_GIFT_LINK } from "src/constants";
import { GiftAnimationComponentDefaultProps } from "src/types/gift";
import lottieDefaultConfig from "src/ui/animations/lottieDefaultConfig";
import LottieAnimation from "ui/common/lottie/LottieAnimation";
import useLottieDataFromZipProcessor from "ui/common/lottie/useLottieDataFromZipProcessor";
import styles from "./GiftAnimation.scss";

const GiftAnimationZip: React.FC<GiftAnimationComponentDefaultProps> = ({
  url,
  assetsPath,
  onComplete,
}) => {
  const { data, isLoading } = useLottieDataFromZipProcessor(url);

  const lottieConfig = useMemo(
    () =>
      data
        ? {
            ...lottieDefaultConfig,
            animationData: data,
          }
        : {
            ...lottieDefaultConfig,
            assetsPath,
            path: DEFAULT_GIFT_LINK,
          },
    [assetsPath, data]
  );

  if (isLoading) {
    return null;
  }

  return (
    <LottieAnimation
      className={styles.root}
      lottieConfig={lottieConfig}
      onComplete={onComplete}
    />
  );
};

export default memo(GiftAnimationZip);
