import React, { memo } from "react";
import classnames from "classnames";
import { Breakpoints } from "src/enums";
import { ToastHeadlineProps } from "src/types/toast";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import { useBreakpoint } from "ui/hooks/useBreakpoint";
import ToastWrapper from "ui/toast/ToastWrapper/ToastWrapper";
import { renderTextOrFormattedMessage } from "utils/renderTextOrFormattedMessage";
import styles from "./Toast.scss";

const ToastHeadline = ({ headline, title, id }: ToastHeadlineProps) => {
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint !== Breakpoints.DESKTOP;
  const headlineTypographyType = isMobile
    ? TYPOGRAPHY_TYPE.HEADLINE4
    : TYPOGRAPHY_TYPE.HEADLINE3;
  const titleTypographyType = isMobile
    ? TYPOGRAPHY_TYPE.PARAGRAPH3
    : TYPOGRAPHY_TYPE.PARAGRAPH2;
  const formattedHeadline = renderTextOrFormattedMessage(headline);
  const formattedTitle = renderTextOrFormattedMessage(title);

  return (
    <ToastWrapper id={id}>
      <div className={classnames(styles.root, styles.headlineContainer)}>
        <Typography type={headlineTypographyType}>
          {formattedHeadline}
        </Typography>
        <Typography className={styles.title} type={titleTypographyType}>
          {formattedTitle}
        </Typography>
      </div>
    </ToastWrapper>
  );
};

export default memo(ToastHeadline);
