import { MicrosoftAdsMethods } from "src/core/analytics/enumsMSAds";
import { GetUserHashedInfoResponse } from "src/features/marketing/api/marketing";

export const initializeMarketingPixel = (resp: GetUserHashedInfoResponse) => {
  window.fbq?.("init", "933804854808402", {
    em: resp?.email,
    ph: resp?.phoneNumber,
  });
  window.fbq?.("track", "PageView");

  window.uetq.push(MicrosoftAdsMethods.SET, {
    pid: {
      em: resp?.hashedEmail,
      ph: resp?.hashedPhoneNumber,
    },
  });
};
