import { LOCATION_CHANGE } from "connected-react-router";
import qs from "query-string";
import { REPLACE } from "enums/browserHistoryActions";

export default (
  state = {
    previous: undefined,
    current: undefined,
    locationState: {},
    search: {},
  },
  action
) => {
  if (action.type === LOCATION_CHANGE) {
    const {
      location: { pathname, search, state: locationState },
      action: historyAction,
    } = action.payload;
    if (pathname !== state.current) {
      if (historyAction === REPLACE) {
        return {
          ...state,
          current: pathname,
          locationState,
          search: qs.parse(search),
        };
      }
      return {
        previous: state.current,
        current: pathname,
        locationState,
        search: qs.parse(search),
      };
    }
    return state;
  }
  return state;
};

export const selectors = {
  getPreviousRoute: (state) => state.previous,
  getCurrentRoute: (state) => state.current,
  getCurrentParsedQuery: (state) => state.search,
  getCurrentLocationState: (state) => state.locationState,
};
