/* eslint-disable react/prop-types */
import React, { useLayoutEffect, useMemo } from "react";
import { defineMessages, useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import isEmpty from "lodash.isempty";
import { CHAT_SOURCE_NOTIFICATION } from "@analytics/chatSource";
import { createLocationStateForNotification } from "@analytics/livePlay/streamLocationState";
import { NOTIFICATION_SHOW_DURATION_MILLIS } from "src/constants";
import { NotificationType, StreamEvents } from "src/enums";
import { MessageType } from "src/types/chat";
import { StreamKind } from "src/types/richFragment/Stream";
import { getParsedExternalMessagePayload } from "src/utils/externalMessage";
import {
  giftsCacheSelectors,
  notificationsSelectors,
  profilesCacheSelectors,
} from "state/selectors";
import { actionCreators } from "state/tree/notifications";
import { formatDisplayName } from "ui/common/DisplayName";
import useExclusiveClickHandler from "ui/hooks/useExclusiveClickHandler";
import { makeLinkToChat, makeLinkToLiveStream } from "ui/navigation/links";
import { chatMessages, previewMessages } from "ui/scenes/chat/common/messages";
import { GiftMessagePreviewText } from "ui/scenes/chat/currentConversation/components/GiftMessagePreviewText";
import { ScreenshotMessageText } from "ui/scenes/chat/currentConversation/components/ScreenshotMessageText";
import defaultAvatar from "img/ic_avatar_placeholder.png";

const singleStreamNotifications = defineMessages({
  public: {
    id: "notification.live.public.body",
    defaultMessage: "started a live broadcast",
  },
  private: {
    id: "notification.live.private.body",
    defaultMessage: "started a private broadcast",
  },
});

const { shareNotification } = defineMessages({
  shareNotification: {
    id: "notification.live.share.body",
    defaultMessage: "My stream ended. Enjoy this one!",
  },
});

const ensureThumbnail = (basicProfile) =>
  basicProfile && basicProfile.profileThumbnailUrl
    ? basicProfile.profileThumbnailUrl
    : defaultAvatar;

const StreamBeganAdapter = ({
  notification,
  basicProfile = {},
  intl,
  history,
}) => {
  const link = makeLinkToLiveStream(
    notification.id,
    createLocationStateForNotification()
  );
  if (history.location.pathname === link.pathname) {
    return null;
  }

  return {
    title: formatDisplayName({ intl, basicProfile }),
    body: intl.formatMessage(
      notification.kind === StreamKind.PUBLIC
        ? singleStreamNotifications.public
        : singleStreamNotifications.private
    ),
    icon: ensureThumbnail(basicProfile),
    tag: notification.broadcasterId,
    onClick: () => history.push(link),
  };
};

const StreamRedirectAdapter = ({ notification, basicProfile, intl }) => ({
  title: formatDisplayName({ intl, basicProfile }),
  body: intl.formatMessage(shareNotification),
  icon: ensureThumbnail(basicProfile),
  tag: notification.accountId,
});

const TcMessages = defineMessages({
  generic: {
    id: "notification.chat.unsupported",
    defaultMessage: "New message!",
  },
  [MessageType.LIKE_MESSAGE]: {
    id: "notification.chat.like",
    defaultMessage: "liked a message",
  },
  [MessageType.GROUP_NAME_CHANGE]: {
    id: "notification.chat.group.name",
    defaultMessage: "changed group name",
  },
  [MessageType.GROUP_MEMBER_JOIN]: {
    id: "notification.chat.group.join",
    defaultMessage: "invited users to the group",
  },
  [MessageType.GROUP_MEMBER_LEAVE]: {
    id: "notification.chat.group.leave",
    defaultMessage: "left the group",
  },
});

const generateBodyForTcMessage = (message, intl) => {
  switch (message.type) {
    case MessageType.TEXT_MESSAGE:
      return message.body;
    case MessageType.SDK_EXTERNAL_MESSAGE: {
      const payload = getParsedExternalMessagePayload(
        message.sdk_message_payload?.payload
      );

      return payload && payload.messageText;
    }
    case MessageType.IMAGE_MESSAGE:
    case MessageType.VIDEO_MESSAGE:
    case MessageType.AUDIO_MESSAGE:
    case MessageType.PROFILE_MESSAGE:
    case MessageType.LIVE_STREAM:
    case MessageType.VIDEO_PTT:
      return intl.formatMessage(previewMessages[message.type]);
    case MessageType.LIKE_MESSAGE:
    case MessageType.GROUP_NAME_CHANGE:
    case MessageType.GROUP_MEMBER_JOIN:
    case MessageType.GROUP_MEMBER_LEAVE:
      return intl.formatMessage(TcMessages[message.type]);
    case MessageType.SCREENSHOT_INFO_MESSAGE: {
      if (message.from) {
        return <ScreenshotMessageText authorAccountId={message.from} />;
      }

      return intl.formatMessage(TcMessages.generic);
    }
    case MessageType.GIFT_IN_CHAT: {
      return <GiftMessagePreviewText message={message} />;
    }
    case MessageType.MISSED_CALL_MESSAGE:
      return intl.formatMessage(chatMessages.missedCall);
    default:
      return intl.formatMessage(TcMessages.generic);
  }
};

const TcMessageAdapter = ({ notification, basicProfile, intl, history }) => {
  const { message, peerId } = notification;
  const link = makeLinkToChat(peerId, CHAT_SOURCE_NOTIFICATION);

  return {
    title: formatDisplayName({ intl, basicProfile }),
    body: generateBodyForTcMessage(message, intl),
    icon: ensureThumbnail(basicProfile),
    tag: notification.accountId,
    onClick: () => history.push(link),
  };
};

const ssGiftMessages = defineMessages({
  default: {
    id: "chat.sent-gift-unknown",
    defaultMessage: "sent a gift",
  },
  song: {
    id: "chat.sent-music-gift",
    defaultMessage: "sent music: {track}",
  },
  gift: {
    id: "chat.sent-gift",
    defaultMessage: "sent a {giftName} gift",
  },
});

const ssGiftMessage = (event, gift, intl) => {
  if (!isEmpty(event.data.songId)) {
    const {
      data: {
        // eslint-disable-next-line camelcase
        songId: { artist_name, track_title },
      },
    } = event;

    return intl.formatMessage(ssGiftMessages.song, {
      // eslint-disable-next-line camelcase
      track: `${artist_name} - ${track_title}`,
    });
  }
  if (gift?.name) {
    return intl.formatMessage(ssGiftMessages.gift, {
      giftName: gift.name,
    });
  }

  return intl.formatMessage(ssGiftMessages.default);
};

const generateBodyForSsMessage = (event, gift, intl) => {
  switch (event.type) {
    case StreamEvents.GIFT:
      return ssGiftMessage(event, gift, intl);
    case StreamEvents.MESSAGE:
      return event.data?.content;
    default:
      return intl.formatMessage(TcMessages.generic);
  }
};

const SsMessageAdapter = ({ notification, basicProfile, intl, gift }) => ({
  title: formatDisplayName({ intl, basicProfile }),
  icon: ensureThumbnail(basicProfile),
  tag: notification.accountId,
  body: generateBodyForSsMessage(notification.event, gift, intl),
  silent: true,
});

const StubAdapter = () => null;

const Adapters = {
  [NotificationType.STREAM_BEGAN]: StreamBeganAdapter,
  [NotificationType.STREAM_REDIRECT]: StreamRedirectAdapter,
  [NotificationType.TC_MESSAGE]: TcMessageAdapter,
  [NotificationType.SS_CHAT_MESSAGE]: SsMessageAdapter,
};

const pickAdapter = ({ type } = {}) => Adapters[type] || StubAdapter;

export default (notificationId) => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const notification = useSelector(
    (state) =>
      notificationsSelectors.getNotificationById(state, notificationId) || {}
  );
  const basicProfile = useSelector(
    (state) =>
      profilesCacheSelectors.getBasicProfile(state, notification.accountId),
    shallowEqual
  );
  const gift = useSelector(
    (state) => giftsCacheSelectors.getGiftById(state, notification.giftId),
    shallowEqual
  );
  const onClose = useExclusiveClickHandler(() =>
    dispatch(actionCreators.clearNotifications([notificationId]))
  );

  const data = useMemo(() => {
    const data = pickAdapter(notification)({
      notification,
      basicProfile,
      intl,
      gift,
      history,
    });
    if (!data) {
      return null;
    }

    return {
      ...data,
      onClose,
      onClick: (...args) => {
        data.onClick && data.onClick(...args);
        onClose();
      },
    };
  }, [notification, basicProfile]);

  useLayoutEffect(() => {
    if (!data) {
      onClose();

      return;
    }
    const timeoutHandle = setTimeout(
      onClose,
      NOTIFICATION_SHOW_DURATION_MILLIS
    );

    return () => clearTimeout(timeoutHandle);
  }, []);

  return data;
};

/* eslint-enable react/prop-types */
