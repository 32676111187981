import { createAction, createReducer } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { ToastStoreType, ToastType } from "src/types/toast";

const initialState: ToastStoreType = {
  list: [],
};

export const addToast = createAction<ToastType>("lwc/toast/addToast");
export const removeToast = createAction<string>("lwc/toast/removeToast");

export const reducer = createReducer<ToastStoreType>(initialState, (builder) =>
  builder
    .addCase(addToast, (state, action) => {
      const id = action.payload.id || uuidv4();
      state.list.push({ ...action.payload, id });
    })
    .addCase(removeToast, (state, action) => {
      state.list = state.list.filter((item) => item.id !== action.payload);
    })
);

export const localToastSelectors = {
  getToastList: (state: ToastStoreType) => state.list,
};

export default reducer;
