import React, { useCallback, useContext } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { EventFields, EventNames } from "@analytics/enums";
import { FormattedMessage } from "react-intl";
import { saveSettings } from "state/flows/settings";
import { emitUiAction } from "@analytics/emitUiAction";
import { emitEvent } from "@analytics/emit";
import ScreenViewReportingContext from "@analytics/screenView/ScreenViewReportingContext";
import emptyFunction from "fbjs/lib/emptyFunction";
import {
  DISABLED,
  ENABLED,
  settingMessages,
  makeEnabledSettingSelector,
} from "ui/scenes/settings/contents/app/SettingsEnum";
import Checkbox from "ui/common/checkbox/Checkbox";
import styles from "./Settings.scss";

const Setting = ({
  optionKey,
  disabled,
  description,
  resetKeysOnDisable,
  setKeysOnEnable,
  onChange = emptyFunction,
  selectorBuilder = makeEnabledSettingSelector,
}) => {
  const dispatch = useDispatch();
  const enabled = useSelector(selectorBuilder(optionKey));
  const { getStack } = useContext(ScreenViewReportingContext);

  const emitCheckAction = useCallback(({ target, status }) => {
    const [tango_screen] = getStack();
    emitUiAction({
      target,
      tango_screen,
      additionalParams: {
        is_checked: status,
      },
    });
    emitEvent(EventNames.CHANGE_SETTING, {
      [EventFields.OLD_VALUE]: status === ENABLED ? DISABLED : ENABLED,
      [EventFields.NEW_VALUE]: status,
    });
    emitEvent(EventNames.USE_SETTING, {
      [EventFields.SETTING_ID]: target,
      [EventFields.VALUE]: status ? ENABLED : DISABLED,
    });
  }, []);

  const onCheckedChange = useCallback(
    (e) => {
      const { checked } = e.target;
      onChange(checked);
      emitCheckAction({
        target: optionKey,
        status: checked ? ENABLED : DISABLED,
      });

      if (checked) {
        const payload = { [optionKey]: ENABLED };
        if (setKeysOnEnable && setKeysOnEnable.length) {
          setKeysOnEnable.forEach((x) => {
            payload[x] = ENABLED;
            emitCheckAction({ target: x, status: ENABLED });
          });
        }
        dispatch(saveSettings(payload));
      } else {
        const payload = { [optionKey]: DISABLED };
        if (resetKeysOnDisable && resetKeysOnDisable.length) {
          resetKeysOnDisable.forEach((x) => {
            payload[x] = DISABLED;
            emitCheckAction({ target: x, status: DISABLED });
          });
        }
        dispatch(saveSettings(payload));
      }
    },
    [optionKey]
  );
  return (
    <Checkbox
      id={`${optionKey.replace(/\./g, "-")}-setting`}
      checked={!disabled && enabled}
      onChange={onCheckedChange}
      className={styles.checkbox}
      label={<FormattedMessage {...settingMessages[optionKey]} />}
      description={description}
      disabled={disabled}
      tabIndex={0}
    />
  );
};

Setting.propTypes = {
  optionKey: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  resetKeysOnDisable: PropTypes.arrayOf(PropTypes.string),
  setKeysOnEnable: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  selectorBuilder: PropTypes.func,
  description: PropTypes.string,
};

export default Setting;
