import { omit } from "src/utils/miniLodash";
import {
  SOCIAL_NETWORK_TOKENS_FETCH_END,
  SOCIAL_NETWORK_TOKENS_REMOVE,
  SOCIAL_NETWORK_TOKENS_UPDATE,
} from "state/actionTypes";

export const persistConfig = {};

const initialState = {};

export const actionCreators = {
  fetchedTokens: ({ payload }) => ({
    type: SOCIAL_NETWORK_TOKENS_FETCH_END,
    payload,
  }),
  deleteToken: ({ accountId, tokenType }) => ({
    type: SOCIAL_NETWORK_TOKENS_REMOVE,
    payload: { tokenType },
    meta: { accountId },
  }),
  updateToken: ({ tokenType, ...rest }) => ({
    type: SOCIAL_NETWORK_TOKENS_UPDATE,
    payload: { tokenType, ...rest },
  }),
};

export const selectors = {
  getTokenForType: (state, tokenType) => {
    const data = state[tokenType];
    return data ? data.token : "";
  },
  isAutoSyncForType: (state, tokenType) => {
    const data = state[tokenType];
    return data ? data.autoSync : false;
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SOCIAL_NETWORK_TOKENS_FETCH_END: {
      return action.payload;
    }
    case SOCIAL_NETWORK_TOKENS_REMOVE: {
      const { tokenType } = action.payload;
      if (!state[tokenType]) {
        return state;
      }
      return omit(state, tokenType);
    }
    case SOCIAL_NETWORK_TOKENS_UPDATE: {
      const { tokenType, ...rest } = action.payload;
      const existingData = state[tokenType] || {};
      return { ...state, [tokenType]: { ...existingData, ...rest } };
    }
  }
  return state;
};
