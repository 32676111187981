import { useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import {
  getIsHomePageEnabled,
  getIsHomePageV2Enabled,
  getIsHomePageV3Enabled,
  getIsVisitorEnabled,
} from "state/abTests";
import { RootState } from "state/delegate";
import { serverOwnedConfigSelectors } from "state/selectors";
import {
  linkToBroadcasterAgreement,
  linkToCommunityGuidelines,
  linkToCopyright,
  linkToDSAInformation,
  linkToFAQ,
  linkToGetTheApp,
  linkToHomePageV3,
  linkToHomeV2Page,
  linkToPrivacyPolicy,
  linkToPublisherTermsAndConditions,
  linkToRegistration,
  linkToRegpage1,
  linkToTermsOfUse,
  linkToThirdPartyLicense,
  linkToWelcome,
} from "ui/navigation/links";

interface UseIsPathnameForVisitorProps {
  pathname: string;
}

const VISITOR_PATH_NAMES: string[] = [
  linkToWelcome,
  linkToHomePageV3,
  linkToHomeV2Page,
  linkToTermsOfUse,
  linkToPrivacyPolicy,
  linkToThirdPartyLicense,
  linkToBroadcasterAgreement,
  linkToCommunityGuidelines,
  linkToCopyright,
  linkToPublisherTermsAndConditions,
  linkToDSAInformation,
  linkToFAQ,
  linkToGetTheApp,
  linkToRegistration,
  linkToRegpage1,
];

const selector = (state: RootState) => ({
  isServerConfigLoaded:
    serverOwnedConfigSelectors.getIsServerConfigLoaded(state),
  isHomePageEnabled: getIsHomePageEnabled(state),
  isHomePageV2Enabled: getIsHomePageV2Enabled(state),
  isHomePageV3Enabled: getIsHomePageV3Enabled(state),
  isVisitorEnabled: getIsVisitorEnabled(state),
});

const useIsPathnameForVisitor = ({
  pathname,
}: UseIsPathnameForVisitorProps) => {
  const {
    isServerConfigLoaded,
    isHomePageEnabled,
    isVisitorEnabled,
    isHomePageV2Enabled,
    isHomePageV3Enabled,
  } = useSelector(selector, shallowEqual);

  const isAnyHomePageEnabled =
    isHomePageEnabled || isHomePageV2Enabled || isHomePageV3Enabled;

  return useMemo(() => {
    // TODO update once https://tango-me.atlassian.net/browse/WEB-6993 is ready
    if (!isServerConfigLoaded || !isVisitorEnabled) {
      return false;
    }

    const visitorPathNames = [
      ...VISITOR_PATH_NAMES,
      ...(isAnyHomePageEnabled ? ["/"] : []),
    ];

    return visitorPathNames.includes(pathname);
  }, [isAnyHomePageEnabled, isServerConfigLoaded, isVisitorEnabled, pathname]);
};

export default useIsPathnameForVisitor;
