import React, { useCallback } from "react";
import { batch, shallowEqual, useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import PropTypes from "prop-types";
import { languageByIsoCode } from "src/constants";
import { Select } from "src/ui/common/select/Select";
import { getSupportedTranslationsLanguages } from "state/abTests";
import { saveSettings } from "state/flows/settings";
import { deviceInfoSelectors } from "state/selectors";
import { actionCreators as deviceInfoActionCreators } from "state/tree/deviceInfo";
import styles from "./LanguagePicker.scss";

const selector = (state) => ({
  locale: deviceInfoSelectors.getDeviceLocale(state),
  socSupportedLanguages: getSupportedTranslationsLanguages(state),
});

const LanguagePicker = ({ className }) => {
  const { locale, socSupportedLanguages } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const onLanguageChange = useCallback(
    (option) => {
      batch(() => {
        dispatch(deviceInfoActionCreators.setLocale(option.value));
        dispatch(saveSettings({ locale: option.value }));
      });
    },
    [dispatch]
  );

  const currentLanguage = {
    value: locale,
    label: languageByIsoCode[locale] || locale,
  };

  const options = socSupportedLanguages.map((language) => ({
    value: language,
    label: languageByIsoCode[language] || language,
  }));

  return (
    <Select
      onChange={onLanguageChange}
      value={currentLanguage}
      options={options}
      containerClassName={classnames(styles.select, className)}
    />
  );
};

export default LanguagePicker;

LanguagePicker.propTypes = {
  className: PropTypes.string,
};
