import React from "react";
import classnames from "classnames";
import styles from "./ContentTitle.scss";

const ContentTitle: React.FC<JSX.IntrinsicElements["div"]> = ({
  className,
  children,
  ...rest
}) => (
  <div className={classnames(styles.title, className)} {...rest}>
    {children}
  </div>
);

export default ContentTitle;
