import { BroadcastExternalState } from "src/features/broadcastExternal/common/types";
import { typedDelegateSelectors } from "src/features/broadcastExternal/imports/state";

export const localBroadcastExternalSelectors = {
  getStreamKey: (state: BroadcastExternalState) => state.streamKey,
  getBroadcastExternalStatus: (state: BroadcastExternalState) => state.status,
};

export const broadcastExternalSelectors = typedDelegateSelectors(
  localBroadcastExternalSelectors,
  "broadcastExternal"
);
