import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { datadogLogs } from "@datadog/browser-logs";
import {
  getSessionTokenLifeTimeCheckInterval,
  getSessionTokenTimeToRefreshBeforeExpiration,
} from "environment";
import { SECOND } from "src/constants";
import { DataDogErrorName } from "src/enums";
import { refreshSessionToken } from "src/state/actionCreators/refreshSessionToken";
import { sessionDetailsSelectors } from "src/state/selectors";
import logger from "src/utils/logger";
import { useMount } from "src/utils/miniReactUse";
import { SessionWorkerMessageType } from "src/workers/refreshToken/enums";
import { WorkerMessage } from "src/workers/refreshToken/type";

const webworker: Worker = new Worker(
  new URL("src/workers/refreshToken/refreshTokenWorker.ts", import.meta.url)
);

const useSessionTokenRefresh = () => {
  const dispatch = useDispatch();
  const lifeTimeCheckInterval = Number(getSessionTokenLifeTimeCheckInterval());
  const timeToRefreshBeforeExpiration =
    getSessionTokenTimeToRefreshBeforeExpiration();
  const expirationTsSec = useSelector(
    sessionDetailsSelectors.getExpirationTime
  );

  const refreshToken = useCallback(async () => {
    try {
      dispatch(refreshSessionToken());
    } catch {
      logger.error("Failed to refresh session token");
      datadogLogs.logger.error(DataDogErrorName.SESSION_TOKEN_REFRESH);
    }
  }, [dispatch]);

  useMount(() => {
    if (expirationTsSec) {
      const isExpirationTimeLessThanInterval =
        expirationTsSec * SECOND - Date.now() <= lifeTimeCheckInterval;

      if (isExpirationTimeLessThanInterval) {
        dispatch(refreshSessionToken());
      }
    }
  });

  useEffect(() => {
    if (!webworker) {
      return;
    }

    webworker.onmessage = async (
      webworkerData: MessageEvent<WorkerMessage>
    ) => {
      if (webworkerData.data.type === SessionWorkerMessageType.REFRESH_TOKEN) {
        const { shouldTokenUpdate } = webworkerData.data.payload;

        if (shouldTokenUpdate) {
          refreshToken();
        }
      }
    };

    return () => {
      webworker.terminate();
    };
  }, [refreshToken]);

  useEffect(() => {
    if (!expirationTsSec) {
      return;
    }

    webworker.postMessage({
      expirationTsSec,
      lifeTimeCheckInterval,
      timeToRefreshBeforeExpiration,
    });
  }, [expirationTsSec, lifeTimeCheckInterval, timeToRefreshBeforeExpiration]);
};

export default useSessionTokenRefresh;
