import React, { FC } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import classnames from "classnames";
import { AdditionalParamsObject } from "@analytics/types";
import { CoinsAmount } from "src/features/welcomeOnboardingOffer/components/CoinsAmount/CoinsAmount";
import {
  EventNames,
  emitEvent,
} from "src/features/welcomeOnboardingOffer/imports/analytics";
import { HTMLButtonProps } from "src/features/welcomeOnboardingOffer/imports/types";
import {
  Button,
  ButtonSize,
  ButtonVariant,
  FormattedPrice,
  TYPOGRAPHY_TYPE,
  Typography,
  sharedMessages,
} from "src/features/welcomeOnboardingOffer/imports/ui";
import { useEffectOnce } from "src/utils/miniReactUse";
import { PayButtonProps } from "ui/modal/modalViews/buyCoins/common/types";
import giftSrc from "./gift.png";
import styles from "./PurchaseNowOffer.scss";

const messages = defineMessages({
  title: {
    id: "welcome.onboarding.purchaseNowOffer.title",
    defaultMessage: "Purchase Now & Get:",
  },
  buy: {
    id: "welcome.onboarding.purchaseNowOffer.buy",
    defaultMessage: "Continue for {price} <old>{oldPrice}</old>",
  },
});

interface PurchaseNowOffer {
  analyticsData: AdditionalParamsObject;
  bonus: number;
  coins: number;
  currencyPrice: PayButtonProps["priceParams"];
  onClick: HTMLButtonProps["onClick"];
}

const FREE_GIFTS = 5;

export const PurchaseNowOffer: FC<PurchaseNowOffer> = ({
  bonus,
  coins,
  currencyPrice,
  onClick,
  analyticsData,
}) => {
  const { value, currency } = currencyPrice;

  const price = value / 100;
  const oldPrice = price * (bonus / 100) + price;

  const { formatMessage } = useIntl();

  const plus = "+";

  useEffectOnce(() => {
    emitEvent(EventNames.OFFER_DISPLAYED, {
      ...analyticsData,
    });
  });

  return (
    <div className={styles.root} data-testid="purchase-now-offer-component">
      <Typography className={styles.title} type={TYPOGRAPHY_TYPE.HEADLINE3}>
        {formatMessage(messages.title)}
      </Typography>

      <div className={styles.benefits}>
        <div className={styles.benefit} data-testid="coins-block">
          <CoinsAmount className={styles.coins} amount={coins} />

          <Typography
            className={styles.textShader}
            type={TYPOGRAPHY_TYPE.HEADLINE5}
          >
            {formatMessage(sharedMessages.coins)}
          </Typography>
        </div>

        <Typography
          className={classnames(styles.textShader, styles.equal)}
          type={TYPOGRAPHY_TYPE.HEADLINE2}
        >
          {plus}
        </Typography>

        <div className={styles.benefit} data-testid="gifts-block">
          <img className={styles.avatar} src={giftSrc} alt="gifts" />

          <Typography
            className={styles.textShader}
            type={TYPOGRAPHY_TYPE.HEADLINE5}
          >
            {`${FREE_GIFTS} `}
            {formatMessage(sharedMessages.gifts)}
          </Typography>
        </div>
      </div>

      <Button
        className={styles.button}
        data-testid="buy-button"
        variant={ButtonVariant.PRIMARY}
        size={ButtonSize.BIG_48}
        onClick={onClick}
      >
        <FormattedMessage
          {...messages.buy}
          values={{
            price: <FormattedPrice value={price} currency={currency} />,
            oldPrice:
              price === oldPrice ? null : (
                <FormattedPrice value={oldPrice} currency={currency} />
              ),
            old: (chunks) => (
              <span className={styles.textShader}>
                <s>{chunks}</s>
              </span>
            ),
          }}
        />
      </Button>
    </div>
  );
};
