import {
  EventFields,
  EventNames,
  SINGLE_CHAT_MESSAGE_SENT_ANCHOR_ID,
  SINGLE_CHAT_MESSAGE_SENT_ANCHOR_TYPE,
  SINGLE_CHAT_MESSAGE_SENT_FLAGS,
} from "@analytics/enums";
import { emitEvent } from "src/analytics/emit";
import { ContentType } from "src/features/chat/premiumMessage/types";

export enum UnlockPremiumMessageResult {
  FAILURE = 0,
  SUCCESS = 1,
}

export type EmitUnlockPremiumMessageParams = {
  accountId: string;
  chatId: string;
  giftId: string;
  messageId: string;
  recipientAccountId: string;
  result: UnlockPremiumMessageResult;
  type: ContentType;
};

export function emitUnlockPremiumMessage(
  params: EmitUnlockPremiumMessageParams
) {
  const {
    accountId,
    chatId,
    giftId,
    messageId,
    recipientAccountId,
    result,
    type,
  } = params;

  emitEvent(EventNames.SINGLE_CHAT_MESSAGE_SENT, {
    [EventFields.FLAGS]: SINGLE_CHAT_MESSAGE_SENT_FLAGS.GIFT,
    [EventFields.ANCHOR_ID]: SINGLE_CHAT_MESSAGE_SENT_ANCHOR_ID.PREMIUM_MESSAGE,
    [EventFields.ANCHOR_TYPE]: SINGLE_CHAT_MESSAGE_SENT_ANCHOR_TYPE.RECEIVED,
    [EventFields.GIFT_ID]: giftId,
    [EventFields.PREMIUM_MESSAGE_ID]: messageId,
    [EventFields.ITEM_TYPE]: type.toLowerCase(),
    [EventFields.RECIPIENT_ACCOUNT_ID]: recipientAccountId,
    [EventFields.LOGGED_IN_ACCOUNT_ID]: accountId,
    [EventFields.RESULT]: result,
    [EventFields.CHAT_ID]: chatId,
  });
}
