import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { allOptionKeys } from "ui/scenes/settings/contents/app/AppSettingsContent";

const KEY_ACCOUNT_ID = "accountId";
const KEY_TOKEN = "token";
const KEY_ONBOARDING = "onboarding";
const KEY_TARGET = "target";

export const AUTOLOGIN_QUERY_KEYS = [KEY_ACCOUNT_ID, KEY_TOKEN, KEY_ONBOARDING];

const getOptionsForMobileSettings = (query) =>
  allOptionKeys.filter((option) => query.get(option) === "true");

const useAutoLoginCredentials = () => {
  const { search } = useLocation();
  return useMemo(() => {
    const query = new URLSearchParams(search);
    const accountId = query.get(KEY_ACCOUNT_ID);
    const token = query.get(KEY_TOKEN);
    const target = query.get(KEY_TARGET);
    const options = getOptionsForMobileSettings(query);
    return {
      accountId,
      token,
      areCredentialsPresent: accountId && token,
      target,
      options,
    };
  }, [search]);
};

export default useAutoLoginCredentials;
