import { ErrorSource } from "@datadog/browser-core";
import { LogsEvent } from "@datadog/browser-logs";

const ABORT_ERROR_KIND = "AbortError";

export default (log: LogsEvent) => {
  const errorOrigin = log?.origin ?? "";
  const statusCode = log?.http?.status_code ?? "";
  const errorKind = log?.error?.kind ?? "";

  if (
    (statusCode === 0 && errorOrigin === ErrorSource.NETWORK) ||
    errorKind.includes(ABORT_ERROR_KIND)
  ) {
    return false;
  }

  return true;
};
