import { EventFields, SearchParams } from "@analytics/enums";
import { getTwitterPurchaseId } from "environment";
import {
  FBEventFields,
  FBEventNames,
  FBMethods,
} from "src/core/analytics/enumsFB";
import {
  MicrosoftAdsEventFields,
  MicrosoftAdsEventNames,
  MicrosoftAdsMethods,
} from "src/core/analytics/enumsMSAds";
import {
  SnapchatEventFields,
  SnapchatEventNames,
  SnapchatMethods,
} from "src/core/analytics/enumsSnapchat";
import {
  TwitterEventFields,
  TwitterMethods,
} from "src/core/analytics/enumsTwitter";
import { Transaction } from "src/core/analytics/types";
import { marketingSelectors } from "src/features/marketing/exports/state";
import { getInMemoryStore } from "utils/getStoreHandler";
import { omitUndefinedProps } from "utils/omitUndefinedProps";

const CURRENCY = "USD";

export const emitMarketingPurchaseSuccess = (
  selectedTransaction: Transaction
) => {
  const userHashedInfo =
    marketingSelectors.getUserHashedInfo(getInMemoryStore());
  const snapchatClickId = localStorage.getItem(SearchParams.SNAPCHAT);

  window.fbq?.(
    FBMethods.TRACK,
    FBEventNames.PURCHASE,
    {
      [FBEventFields.CURRENCY]: CURRENCY,
      [FBEventFields.VALUE]: selectedTransaction.amount_usd,
    },
    {
      [FBEventFields.EVENT_ID]: selectedTransaction[EventFields.TRANSACTION_ID],
    }
  );

  window.twq?.(
    TwitterMethods.EVENT,
    getTwitterPurchaseId(),
    omitUndefinedProps({
      currency: CURRENCY,
      value: selectedTransaction.amount_usd,
      [TwitterEventFields.CLIENT_DEDUP_ID]: selectedTransaction.transaction_id,
      [TwitterEventFields.EMAIL_ADDRESS]: userHashedInfo?.email,
      [TwitterEventFields.PHONE_NUMBER]: userHashedInfo?.phoneNumber
        ? `+${userHashedInfo?.phoneNumber}`
        : undefined,
    })
  );

  window.snaptr?.(SnapchatMethods.TRACK, SnapchatEventNames.PURCHASE, {
    [EventFields.TRANSACTION_ID]: selectedTransaction.transaction_id,
    [SnapchatEventFields.CLIENT_DEDUP_ID]: selectedTransaction.transaction_id,
    [SnapchatEventFields.HASHED_PHONE]: userHashedInfo?.hashedPhoneNumber,
    [SnapchatEventFields.HASHED_EMAIL]: userHashedInfo?.hashedEmail,
    [SnapchatEventFields.CLICK_ID]: snapchatClickId,
  });

  window.uetq?.push(
    MicrosoftAdsMethods.EVENT,
    MicrosoftAdsEventNames.PURCHASE,
    {
      [MicrosoftAdsEventFields.REVENUE_VALUE]: selectedTransaction.amount_usd,
      [MicrosoftAdsEventFields.CURRENCY]: CURRENCY,
      pid: {
        [MicrosoftAdsEventFields.EMAIL]: userHashedInfo?.email,
        [MicrosoftAdsEventFields.PHONE_NUMBER]: userHashedInfo?.phoneNumber
          ? `+${userHashedInfo?.phoneNumber}`
          : undefined,
      },
    }
  );
};
