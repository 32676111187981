import { createAsyncThunk } from "@reduxjs/toolkit";
import { isApiError } from "api/utils/enhancedFetch";
import { loadReferredUsersListRequest } from "src/features/referralProgram/api/referralUsersApi";
import {
  ReferredUsersListRequest,
  ReferredUsersListResponse,
} from "src/features/referralProgram/common/types";
import { RootState } from "state/delegate";

export const loadReferralUsersList = createAsyncThunk<
  ReferredUsersListResponse,
  ReferredUsersListRequest,
  { rejectValue: string; state: RootState }
>("lwc/referredUsers/loadReferralUsersList", async (payload, api) => {
  try {
    return await loadReferredUsersListRequest(payload);
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;

    return api.rejectWithValue(error);
  }
});
