import React, { ElementType, useMemo } from "react";
import classnames from "classnames";
import { MAX_NOTIFICATIONS } from "src/constants";
import { ToastType } from "src/enums";
import { useBreakpoint } from "ui/hooks/useBreakpoint";
import { useToast } from "ui/hooks/useToast";
import { ToastHeadline, ToastRegular, ToastTimer } from "ui/toast/Toast";
import styles from "./ToastController.scss";

const toastComponentByType: {
  [key: string]: ElementType;
} = {
  [ToastType.REGULAR]: ToastRegular,
  [ToastType.TIMER]: ToastTimer,
  [ToastType.HEADLINE]: ToastHeadline,
};

export const ToastController = () => {
  const { list } = useToast();
  const breakpoint = useBreakpoint();
  const listSlice = useMemo(
    () => list?.slice(0, MAX_NOTIFICATIONS).reverse(),
    [list]
  );

  return (
    <div className={classnames(styles.root, styles[breakpoint])}>
      {listSlice?.length > 0 && (
        <div className={styles.list}>
          {listSlice.map(({ id, type, ...rest }) => {
            const ToastComponent = toastComponentByType[type];

            return <ToastComponent key={id} id={id} {...rest} />;
          })}
        </div>
      )}
    </div>
  );
};
