import { batch } from "react-redux";
import { createListenerMiddleware } from "@reduxjs/toolkit";
import { ChatGiftMessage } from "generated/proto/ChatGiftMessage";
import {
  fetchConversation,
  fetchConversations,
} from "src/state/actionCreators/chat";
import { RootState } from "src/state/delegate";
import { chatSelectors, userSelectors } from "src/state/selectors";
import { MessageType } from "src/types/chat";
import { Gift } from "src/types/gift";
import { parseMessageFromBase64 } from "src/utils/protobufUtil";
import { addGiftToPlay } from "./slice";

export const addChatGiftToPlayMiddleware =
  createListenerMiddleware<RootState>();

addChatGiftToPlayMiddleware.startListening({
  actionCreator: fetchConversation.fulfilled,
  effect: (action, { dispatch, getState }) => {
    const {
      conversation: { unread_message_count: unreadMessageCount = 0 } = {},
      messages = [],
    } = action.payload.conversation || {};

    if (unreadMessageCount) {
      const state = getState();
      const myAccountId = userSelectors.getMyAccountId(state);

      const lastUnreadGiftMessage = messages
        .slice(0, unreadMessageCount)
        .find(
          (message) =>
            message.type === MessageType.GIFT_IN_CHAT &&
            message.from !== myAccountId
        );

      if (lastUnreadGiftMessage) {
        const { giftId } = parseMessageFromBase64(
          lastUnreadGiftMessage.payload,
          ChatGiftMessage
        );

        dispatch(addGiftToPlay(giftId));
      }
    }
  },
});

addChatGiftToPlayMiddleware.startListening({
  actionCreator: fetchConversations.fulfilled,
  effect: (action, { dispatch, getState }) => {
    const currentConversationId =
      chatSelectors.getCurrentConversationId(getState());

    const { conversations = [] } = action.payload;

    conversations.forEach(
      ({
        conversation: { conversation_id: conversationId },
        messages = [],
      }) => {
        if (!conversationId || conversationId !== currentConversationId) {
          return;
        }

        const giftUnreadMessages = messages.slice(0, -1).reduce(
          (gifts, message) => {
            if (message.type === MessageType.GIFT_IN_CHAT) {
              const { giftId } = parseMessageFromBase64(
                message.payload,
                ChatGiftMessage
              );

              gifts.unshift(giftId);
            }

            return gifts;
          },
          [] as Gift["id"][]
        );

        if (giftUnreadMessages.length) {
          batch(() => {
            giftUnreadMessages.forEach((giftId) => {
              dispatch(addGiftToPlay(giftId));
            });
          });
        }
      }
    );
  },
});
