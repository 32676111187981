import { shopActionCreators as actionCreators } from "state/tree/shop";
import { shopSelectors } from "state/selectors";
import genericShopFetcher from "./utils/genericShopFetcher";
import { getCashierOffersVersion } from "src/environment";

const fetcher = genericShopFetcher({
  actionCreators,
  selectors: shopSelectors,
});

export default (dispatch) =>
  dispatch(fetcher(`${getCashierOffersVersion()}-ZERO`));
