import { GetConversationResponse } from "src/api/chat";
import {
  ChatPrivacySettings,
  ConversationState,
  Message,
  ResponseCode,
} from "src/features/chat/messageRequest/imports/types";

export enum ParamConversationState {
  ACTIVE,
  CHAT_REQUEST,
}

export type MessageIdentifier = {
  chat_id?: string;
  id: number;
  ts: number;
};

export interface GetMessageRequestsParams {
  direction: Direction;
  include_account_info?: boolean;
  include_group_info?: boolean;
  include_group_members?: boolean;
  include_messages?: boolean;
  isRefreshing?: boolean;
  last_update_request_timestamp?: number;
  limit_conversations?: number;
  limit_messages_per_conversation?: number;
  resetOnError?: boolean;
  state?: ParamConversationState.CHAT_REQUEST;
}

export type GetMessageRequestParams = {
  direction?: Direction;
  end_timestamp?: number;
  include_group_info?: boolean;
  include_group_members?: boolean;
  limit?: number;
  start_timestamp?: number;
};

// TODO: remove reducer re-assign, make common conversations list
// for all chat types (chat, message request, group chat)
export type GetConversationMessageRequestParams = GetConversationResponse;

export type GetMessageRequestResponse = {
  conversation?: Conversation;
  status: ResponseStatus;
};

export interface GetMessageRequestsResponse {
  conversations?: Conversation[];
  hasMoreConversations?: boolean;
  status?: ResponseStatus;
  totalConversationRequestCount?: string;
  totalUnreadCount?: string;
}

export interface DeleteMessageRequestsResponse {
  status?: ResponseStatus;
}

export interface DeleteMessageRequestsParams {
  conversationId: string;
}

export type AccountInfo = {
  accountId: string;
  firstName?: string;
  lastName?: string;
  thumbnailUrl?: string;
  vipConfigId?: string;
  vipLevel?: number;
};

export type ConversationInfo = {
  accountInfo?: AccountInfo;
  conversationId: string;
  hidden?: boolean;
  lastMessageTs?: string;
  lastSelfReadMessageTs?: string;
  lastUpdateTs?: string;
  state?: ConversationState;
  unreadMessageCount?: string;
};

export type StoredConversation = {
  hasMoreMessages?: boolean;
  isLoading?: boolean;
  isLoadingFailed?: boolean;
} & ConversationInfo;

type StoredMessageMedia = {
  caption?: string;
  isUploading?: boolean;
} & MessageMedia;

export type StoredMessage = {
  error?: string;
  id: { requestId?: string } & MessageIdentifier;
  isMediaLoading?: boolean;
  isPending?: boolean;
  isTranslated?: boolean;
  isTranslating?: boolean;
  language?: string;
  media?: StoredMessageMedia[];
  translation?: Record<string, string>;
} & Omit<Message, "id" | "media">;

export type ResponseStatus = {
  code: ResponseCode;
  message?: string;
  timestamp: number;
};

export enum Direction {
  FORWARD = "FORWARD",
  REVERSE = "REVERSE",
}

export enum Mode {
  AUDIO,
  VIDEO,
}

export type MessageMedia = {
  creation_ts?: number;
  download_url?: string;
  duration?: number;
  height?: number;
  media_id?: string;
  size?: number;
  thumbnail_url?: string;
  type?: number;
  width?: number;
};

export type Conversation = {
  conversation: ConversationInfo;
  has_more_messages?: boolean;
  messages?: Message[];
  peer_preferences?: ChatPrivacySettings;
};

export type ReadMessageRequestConversationResponse = {
  status: ResponseStatus;
  totalUnreadCount?: string;
  unreadCount?: string;
};
