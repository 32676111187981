import { ContentSharedPayloadMessage } from "src/features/chat/premiumMessage/types";
import { PremiumMessageState } from "./slice";

export const premiumMessageSelectors = {
  getPending: (
    state: PremiumMessageState,
    messageId: ContentSharedPayloadMessage["messageId"]
  ) => state.pending[messageId],
  getDetails: (state: PremiumMessageState) => state.details,
  getDetail: (
    state: PremiumMessageState,
    messageId: ContentSharedPayloadMessage["messageId"]
  ) => state.details[messageId],
};
