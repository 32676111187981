import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { IBrowser, IOS } from "ua-parser-js";
import { DeviceType } from "src/enums";
import { LanguageIsoCode } from "src/types/common";

export const deviceTypeDetected = createAction<{
  browserName: IBrowser["name"];
  os: IOS["name"];
  type: DeviceType;
  version: IOS["version"];
}>("lwc/device/typeDetected");
export const setLocale = createAction<LanguageIsoCode>("lwc/device/setLocale");
export const setWebview = createAction<boolean>("lwc/device/setWebview");

export const getFingerprint = createAsyncThunk<string>(
  "lwc/device/getFingerprint",
  async () => {
    const fp = await FingerprintJS.load();
    const { components } = await fp.get();
    const {
      colorDepth,
      colorGamut,
      screenResolution,
      screenFrame,
      contrast,
      hdr,
      forcedColors,
      monochrome,
      reducedMotion,
      fontPreferences,
      ...stableComponents
    } = components;

    return FingerprintJS.hashComponents(stableComponents);
  }
);
