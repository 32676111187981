import { useMemo } from "react";
import { useSelector } from "react-redux";
import { isAfter, isBefore } from "date-fns";
import { RootState } from "state/delegate";
import {
  countryByIpSelectors,
  loginSelectors,
  profilesCacheSelectors,
  userSelectors,
} from "state/selectors";
import {
  CountriesFilterSoc,
  CountriesSoc,
  CountryFilterType,
  DataOrFunctionThatReturnsData,
  DateCompareFunction,
  DateFilterType,
  DateSoc,
  EmptyArgsFn,
  FeatureControlProperties,
  IdsListSoc,
  IsEnabledSoc,
  RegisterDateFilterSoc,
} from "ui/hooks/useFeatureEnabled/types";

const extractData = <T>(soc: DataOrFunctionThatReturnsData<T>) =>
  typeof soc === "function" ? (soc as EmptyArgsFn<T>)() : soc;

const extractList = <T>(soc: EmptyArgsFn<T[]> | T[]): T[] => {
  const list = extractData(soc);

  if (!list) {
    return [];
  }

  return Array.isArray(list) ? list : [list];
};

export const createCountryMatchSelector =
  (
    countriesSOC: CountriesSoc,
    countryFilter: CountriesFilterSoc = CountryFilterType.INCLUDE
  ) =>
  (state: RootState) => {
    const countriesList = extractList(countriesSOC);
    const filter = extractData(countryFilter);
    const isGuest = !loginSelectors.isLoggedIn(state);
    const myAccountId = userSelectors.getMyAccountId(state);
    const countryByIp = countryByIpSelectors.data(state);
    const countryByIpMeta = countryByIpSelectors.meta(state);
    const countryCode = profilesCacheSelectors.getProfileDetails(
      state,
      myAccountId
    )?.iso2CountryCode as string | undefined;
    const isLoadingProfile = profilesCacheSelectors.isLoadingProfileById(
      state,
      myAccountId,
      { detail: true }
    );

    const hasAsterisk = countriesList.includes("*");
    const loading = isGuest
      ? countryByIpMeta.stale || countryByIpMeta.loading
      : isLoadingProfile;
    const includesCountry = isGuest
      ? countryByIp !== null &&
        countriesList.includes(countryByIp?.toLowerCase())
      : countryCode !== undefined &&
        countriesList.includes(countryCode?.toLowerCase());
    switch (filter) {
      case CountryFilterType.EXCLUDE: {
        // if * is in list - we disable feature for user
        if (hasAsterisk) {
          return false;
        }

        return !countriesList.length || (!loading && !includesCountry);
      }
      case CountryFilterType.INCLUDE:
      default:
        return hasAsterisk || (!loading && includesCountry);
    }
  };

export const createEnableByDateSelector =
  (
    isEnabledSOC: IsEnabledSoc,
    dateSOC: DateSoc,
    filterSoc: RegisterDateFilterSoc
  ) =>
  (state: RootState) => {
    const isEnabled = extractData(isEnabledSOC);
    const filterType = extractData(filterSoc);
    const isGuest = !loginSelectors.isLoggedIn(state);
    const myAccountId = userSelectors.getMyAccountId(state);
    let compare: DateCompareFunction;
    switch (filterType) {
      case DateFilterType.AFTER:
        compare = isAfter;
        break;
      case DateFilterType.BEFORE:
      default:
        compare = isBefore;
        break;
    }
    const date = extractData(dateSOC);
    const profileDetails = profilesCacheSelectors.getProfileDetails(
      state,
      myAccountId
    ) || { registrationTime: 0 };

    const userRegisterDate = profileDetails.registrationTime
      ? new Date(profileDetails.registrationTime)
      : null;

    if (!isEnabled || isGuest) {
      return true;
    }
    if (!userRegisterDate || !date) {
      return false;
    }

    return compare(userRegisterDate, date);
  };

export const createEnableForGuestSelector =
  (enableGuestSOC: IsEnabledSoc) => (state: RootState) => {
    const isEnabled = extractData(enableGuestSOC);
    const isGuest = !loginSelectors.isLoggedIn(state);
    if (!isGuest) {
      return true;
    }

    return isEnabled;
  };

export const createFeatureEnabledSelector = (
  enableSOCs: FeatureControlProperties
) => {
  const countryMatchSelector = createCountryMatchSelector(
    enableSOCs.country,
    enableSOCs.countryFilter
  );
  const enableByDateSelector = createEnableByDateSelector(
    enableSOCs.registerDateEnabled,
    enableSOCs.registerDate,
    enableSOCs.registerDateFilter
  );
  const enableForGuestSelector = createEnableForGuestSelector(enableSOCs.guest);

  return (state: RootState) =>
    extractData(enableSOCs.enabled) &&
    enableForGuestSelector(state) &&
    countryMatchSelector(state) &&
    enableByDateSelector(state);
};

export const useAccountIdMatch = (allowedIdsSoc: IdsListSoc) => {
  const allowedIdsList =
    // eslint-disable-next-line no-nested-ternary
    typeof allowedIdsSoc === "function"
      ? allowedIdsSoc()
      : Array.isArray(allowedIdsSoc)
        ? allowedIdsSoc
        : [allowedIdsSoc];

  const accountId = useSelector(userSelectors.getMyAccountId);

  return allowedIdsList?.includes("*") || allowedIdsList?.includes(accountId);
};

const useFeatureEnabled = (enableSOCs: FeatureControlProperties) => {
  const featureEnabledSelector = useMemo(
    () => createFeatureEnabledSelector(enableSOCs),
    [enableSOCs]
  );

  return useSelector(featureEnabledSelector);
};

export default useFeatureEnabled;
