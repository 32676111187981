import React, { FC, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "src/state/delegate";
import { profilesCacheSelectors, userSelectors } from "state/selectors";
import { formatDisplayName } from "ui/common/DisplayName";
import { messages } from "ui/scenes/chat/currentConversation/components/ScreenshotMessageText/messages";

export interface ScreenshotMessageTextProps {
  authorAccountId: string;
}

export const ScreenshotMessageText: FC<ScreenshotMessageTextProps> = ({
  authorAccountId,
}) => {
  const intl = useIntl();

  const { basicProfile, isMyMessage } = useSelector(
    useCallback(
      (state: RootState) => ({
        basicProfile: profilesCacheSelectors.getBasicProfile(
          state,
          authorAccountId
        ),
        isMyMessage: userSelectors.getMyAccountId(state) === authorAccountId,
      }),
      [authorAccountId]
    ),
    shallowEqual
  );

  return isMyMessage ? (
    <FormattedMessage {...messages.youScreenshotted} />
  ) : (
    <FormattedMessage
      {...messages.someoneScreenshotted}
      values={{ someone: formatDisplayName({ basicProfile, intl }) }}
    />
  );
};
